import React from "react";

import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import git from "../../assets/3d-glassy-glowing-cube (1).gif";
import vector from "../../assets/vic1.png";
import acc1 from "../../assets/acc1.png";
import acc2 from "../../assets/acc2.png";
import acctext from "../../assets/acctext.svg";
import acctext1 from "../../assets/acctext1.svg";
import sm2 from "../../assets/age1m.svg";
import agh from "../../assets/agh.svg";
import bal from "../../assets/bal1.svg";
import sm1 from "../../assets/cre1m.svg";
import homecr1 from "../../assets/creativef.svg";
import hh from "../../assets/hh.svg";
import homeimg from "../../assets/homeim.svg";
import hh1 from "../../assets/image.svg";
import Header from "../header";
import "./index.scss";
const SlideHome = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/service");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  return (
    <div className="slide-contact">
      <div className="header-home">
        <Header />
      </div>
      <div className="slide-contact-container disp-web ">
        <div className="row w-100 row-margin-h ">
          <div className=" col-3"></div>
          <div className="col-7  ">
            {" "}
            <div
              style={{
                overflow: "hidden",
                height: "135px", // La hauteur de la zone visible
                position: "relative",
              }}
            >
              <motion.img
                src={homecr1} // Remplacez par votre image
                alt="Scrolling"
                initial={{ y: "0%" }} // Commence en haut
                animate={{ y: "-92.8%" }} // Se déplace vers le bas
                transition={{
                  duration: 10, // Durée de l'animation (en secondes)
                  ease: "linear", // Mouvement fluide
                }}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="col-2">
            {" "}
            <motion.img
              src={bal} // Remplacez par l'URL ou le chemin de votre image
              alt="Image flottante"
              animate={{
                y: [0, -20, 0], // Déplacement vertical (flottement)
              }}
              transition={{
                duration: 2, // Durée d'un cycle de flottement
                repeat: Infinity, // Répéter l'animation indéfiniment
                repeatType: "loop", // Répéter en boucle
                ease: "easeInOut", // Type d'easing
              }}
              style={{ width: "100%", height: "auto", marginTop: "-50px" }}
            />
          </div>
        </div>
        <div className="row w-100 d-flex row-margin-h2  mb-5">
          <div className="col-11  position-relative ">
            {" "}
            <img
              src={homeimg}
              alt="Image à droite"
              className="w-100  h-100 
             img-fluid"
            />
            <div className="image-overlay-acceuil">
              <img
                src={git}
                alt="Image à droite"
                style={{ width: "50%", height: "auto" }}
              />
            </div>
          </div>
        </div>
        <div className="row w-100 gx-0 mt-3  justify-content-center  mb-5 margin-top-acc">
          <div className="col-4 d-flex justify-content-center align-items-center ">
            {" "}
            <img src={acc1} alt="Image à droite" className="  img-fluid zome" />
          </div>
          <div className="col-4 d-flex flex-column align-items-center justify-content-center">
            <p className="text-slide">
              MSIT Conseil met son expertise à votre service dans trois domaines
              clés : Consulting, Développement et Marketing. Ensemble,
              construisons des solutions sur mesure adaptées à vos besoins.
            </p>
            <button
              class="glow-on-hover mt-3"
              type="button"
              onClick={handleNavigation}
            >
              Nos Services
            </button>
          </div>
          <div className="col-4    d-flex justify-content-center align-items-start position-relative">
            {" "}
            <img
              src={acc2}
              alt="Image à droite"
              className="  img-fluid imgtext"
            />
            <div className="image-overlay-text w-100  flex-column d-flex justify-content-end align-items-end">
              <img
                src={acctext1}
                alt="Image à droite"
                className="  img-fluid  "
              />
              <img src={acctext} alt="Image à droite" className="  img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="slide-contact-container disp-mobile">
        {/* Premier row */}
        <div className="row w-100 row-margin-h">
          <div className="col-9">
            <img
              src={agh}
              alt="Image à droite"
              className="h-100 w-100 img-fluid"
            />
          </div>
          <div className="col-3"></div>
        </div>

        {/* Deuxième row */}
        <div
          className="row mx-auto position-relative"
          style={{ minHeight: "180px" }}
        >
          <img
            src={vector}
            alt="Image à droite"
            className="img-fluid "
            style={{
              position: "absolute", // Position absolue
              top: "0px", // Distance du haut
              right: "00px", // Distance de la droite
              zIndex: 1, // Optionnel : Contrôle l'empilement
            }}
          />
          <div
            style={{
              overflow: "hidden",
              height: "60px", // La hauteur de la zone visible
              position: "relative",
              zIndex: 1000,
            }}
          >
            <motion.img
              src={homecr1} // Remplacez par votre image
              alt="Scrolling"
              initial={{ y: "0%" }} // Commence en haut
              animate={{ y: "-92.8%" }} // Se déplace vers le bas
              transition={{
                duration: 10, // Durée de l'animation (en secondes)
                ease: "linear", // Mouvement fluide
              }}
              style={{ width: "80%", marginLeft: "120px" }}
            />
          </div>
          <div className="image-overlay-acceuil">
            <img
              src={git}
              alt="Image à droite"
              style={{ width: "50%", height: "auto" }}
            />
          </div>
        </div>

        {/* Troisième row */}
        <div className="row p-3 g-2 gx-0">
          {/* Premier colonne avec image */}
          <div className="col-6 d-flex h-100">
            <img
              src={hh1}
              alt="Image à droite"
              className=" w-100 h-100 object-fit-cover" // Utilisation de object-fit-cover pour ajuster l'image
            />
          </div>

          {/* Deuxième colonne avec image */}
          <div className="col-6 d-flex h-100">
            <img
              src={hh}
              alt="Image à droite"
              className=" w-100 h-100 object-fit-cover" // Utilisation de object-fit-cover pour ajuster l'image
            />
          </div>
        </div>
        <div className="row mx-auto position-relative">
          <div className="col-1"></div>
          <div className="col-10 d-flex flex-column align-items-center">
            {" "}
            <p className="text-slide">
              Vous cherchez le meilleur consultant ? <br />{" "}
              <span className="span-slide">
                MSIT Conseil met son expertise à votre service dans trois
                domaines clés : Consulting, Développement et Marketing.
                Ensemble, construisons des solutions sur mesure adaptées à vos
                besoins.
              </span>{" "}
            </p>
            <button
              class="glow-on-hover mt-3"
              type="button"
              onClick={handleNavigation}
            >
              Explorer
            </button>
          </div>
        </div>
        <div className="row mx-auto position-relative mt-5">
          {" "}
          <img
            src={sm1}
            alt="Image à droite"
            className="img-fluid h-100" // Utilisation de object-fit-cover pour ajuster l'image
          />
          <div className="col-2"></div>
          <div className="col-8  d-flex flex-column align-items-center">
            {" "}
            <img
              src={sm2}
              alt="Image à droite"
              className="  " // Utilisation de object-fit-cover pour ajuster l'image
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideHome;
