import axios from "../../config/axiosConfig";

export default class UserService {
  loginUser = async (data) => {
    try {
      const response = axios.post("/users/login", data);
      return response;
    } catch (error) {
      throw new Error("Erreur lors de la connexion: " + error.message);
    }
  };
  VerifCodeUser = async (data) => {
    try {
      const response = axios.post("/users/verify-code", data);
      return response;
    } catch (error) {
      throw new Error("Erreur lors de la connexion: " + error.message);
    }
  };
  logout = async (data) => {
    try {
      const response = axios.post("/users/logout", data);
      return response;
    } catch (error) {
      throw new Error("Erreur lors de la connexion: " + error.message);
    }
  };
  getAllusers = async () => {
    try {
      const response = axios.get("users");
      return response;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la users : " + error.message
      );
    }
  };
}
