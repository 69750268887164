import React, { useState } from "react";
import "./index.scss";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import branding from "../../assets/BRANDING.svg";
import sm from "../../assets/sm.svg";
import ws from "../../assets/ws.svg";
import CustomSliderSm from "../customSliderSm";
import CustomSliderSw from "../customSliderSw";
import CustomSlider from "../sliderCust";
const Tabs = () => {
  const [activeTab, setActiveTab] = useState("branding");

  const renderContent = () => {
    switch (activeTab) {
      case "branding":
        return (
          <div className="tab-content w-100">
            <img
              src={branding}
              alt="Image à droite"
              className="design-img img-fluid w-100"
            />{" "}
            <CustomSlider />
          </div>
        );
      case "socialMedia":
        return (
          <div className="tab-content w-100">
            <img
              src={sm}
              alt="Image à droite"
              className="design-img img-fluid w-100"
            />{" "}
            <CustomSliderSm />
          </div>
        );
      case "website":
        return (
          <div className="tab-content w-100">
            <img
              src={ws}
              alt="Image à droite"
              className="design-img img-fluid w-100"
            />{" "}
            <CustomSliderSw />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div className="tabs">
        <button
          className={`tab ${activeTab === "branding" ? "active" : ""}`}
          onClick={() => setActiveTab("branding")}
        >
          BRANDING
        </button>
        <button
          className={`tab ${activeTab === "socialMedia" ? "active" : ""}`}
          onClick={() => setActiveTab("socialMedia")}
        >
          SOCIAL MEDIA
        </button>
        <button
          className={`tab ${activeTab === "website" ? "active" : ""}`}
          onClick={() => setActiveTab("website")}
        >
          WEBSITE
        </button>
      </div>
      <div className="content">{renderContent()}</div>
    </>
  );
};

export default Tabs;
