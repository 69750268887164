import React, { useState } from "react";
import CandidatureService from "../../services/candidature/condidature.service";
import { showErrorToaster } from "../toaster/error";
import { showSuccessToaster } from "../toaster/success";
import "./index.scss";
const candidatureService = new CandidatureService();

const Modal = ({ showModal, handleClose, missionId }) => {
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    profile: "",
    linkedin: "",
    missionId: missionId,
    lettreMotivation: "",
  });
  console.log(missionId);

  const [cvFile, setCvFile] = useState(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setCvFile(file); // Sauvegarder le fichier dans l'état
    }
  };

  const handleSubmit = async () => {
    if (!cvFile) {
      showErrorToaster("Veuillez sélectionner un fichier.");
      return;
    }

    // Création d'un FormData
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("lastname", formData.lastname);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("profile", formData.profile);
    formDataToSend.append("linkedin", formData.linkedin);
    formDataToSend.append("missionId", missionId);
    formDataToSend.append("cv", cvFile); // Ajouter le fichier au FormData
    formDataToSend.append("lettreMotivation", formData.lettreMotivation);

    for (let pair of formDataToSend.entries()) {
      console.log(pair[0] + ": " + pair[1]); // Afficher le contenu de FormData pour débogage
    }

    try {
      const response = await candidatureService.addCandidature(formDataToSend);

      if (response.status === 201) {
        // Réinitialisation du formulaire
        setFormData({
          name: "",
          lastname: "",
          email: "",
          phone: "",
          profile: "",
          linkedin: "",
          missionId: missionId,
          lettreMotivation: "",
        });
        setCvFile(null); // Réinitialiser le fichier
        handleClose();
        showSuccessToaster("Candidature soumise avec succès!");
      } else {
        showErrorToaster("Erreur lors de la soumission de la candidature");
      }
    } catch (error) {
      console.error("Erreur réseau:", error);
      showErrorToaster("Erreur lors de la soumission de la candidature");
    }
  };

  if (!showModal) return null;

  return (
    <div className="modal-overlay-mission">
      <div className="modal-content-mission">
        <button className="close-button-mission" onClick={handleClose}>
          X
        </button>
        <h2 className="candidature-titre">Formulaire de Candidature</h2>
        <div className="form-mission">
          <div className="row ">
            <div className=" col-6 form-group-mission">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Nom"
              />
            </div>
            <div className=" col-6 form-group-mission">
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                required
                placeholder="Prénom"
              />
            </div>
          </div>
          <div className="row ">
            <div className=" col-6 form-group-mission">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Email"
              />
            </div>

            <div className=" col-6 form-group-mission">
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                placeholder="Téléphone"
              />
            </div>
          </div>
          <div className="  form-group-mission">
            <select
              id="profile"
              className="form-select styled-select"
              value={formData.profile}
              onChange={handleChange}
              required
            >
              <option value="" className="default-option" disabled hidden>
                Votre profile
              </option>
              <option value="Développeur web" className="custom-option">
                Développeur web
              </option>
              <option value="Développeur mobile" className="custom-option">
                Développeur mobile
              </option>
              <option value="UX/UI Designer" className="custom-option">
                UX/UI Designer
              </option>
              <option value="Designer graphique" className="custom-option">
                Designer graphique
              </option>
              <option value="Marketing Digital" className="custom-option">
                Marketing Digital
              </option>
              <option value="autre" className="custom-option">
                Autre
              </option>
            </select>
          </div>
          <div className="form-group-mission">
            <input
              type="linkedin"
              id="linkedin"
              name="linkedin"
              value={formData.linkedin}
              onChange={handleChange}
              required
              placeholder="Lien de votre LinkedIn"
            />
          </div>
          <div className="form-group-mission">
            <label htmlFor="cv" className="file-label">
              Télécharger votre CV (PDF)
            </label>
            <input
              type="file"
              id="cv"
              name="cv"
              accept=".pdf"
              onChange={handleFileChange}
              required
              placeholder="CV (PDF)"
            />
          </div>

          <div className="form-group-mission">
            <textarea
              id="lettreMotivation"
              name="lettreMotivation"
              value={formData.lettreMotivation}
              onChange={handleChange}
              required
              placeholder="Message"
            />
          </div>

          <button onClick={handleSubmit} className="submit-button-mission">
            Envoyer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
