import React, { useEffect, useState } from "react";

import { showErrorToaster } from "../../../components/toaster/error";

import { useNavigate } from "react-router-dom";
import DataTableDisplay from "../../../components/tableMission";

import UserService from "../../../services/user-auth/user.service";

const userService = new UserService();

function UserListePages() {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    setIsLoading(true);

    try {
      const res = await userService?.getAllusers();
      setUsers(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  function getImageURL(userId) {
    return `${apiUrl}/users/${userId}/image`;
  }

  const columnsuser = [
    {
      name: "Nom",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.name != null && row?.name}
        </div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.email != null && row?.email}
        </div>
      ),
      sortable: true,
    },
    {
      name: "image",
      selector: (row) => (
        <div className="table-text capitalize">
          <img
            src={getImageURL(row?.id)}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
      ),
      sortable: true,
    },

    {
      name: "Role",
      selector: (row) => (
        <div className="table-text">{row?.role != null && row?.role}</div>
      ),
      hide: "md",
      sortable: true,
    },
  ];

  return (
    <section className="usersPage">
      <div className="container-fluid">
        <div className="row  my-0 my-md-5 align-items-center">
          <div className="col-sm text-center text-sm-start">
            <h3>Utilisateur liste</h3>
          </div>
          <div className="col-sm d-lg-flex justify-content-end text-center text-sm-start ">
            <div className="d-flex gap-2 justify-content-center justify-content-sm-between"></div>
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex  my-0 my-md-5 justify-content-center align-items-center"></div>
        ) : (
          <div className=" my-0 my-md-5 py-2">
            <DataTableDisplay columns={columnsuser} data={users} />
          </div>
        )}
      </div>
    </section>
  );
}

export default UserListePages;
