import React from "react";
import "./index.scss";

const AppButton = ({
  text,
  onClick,
  color = "blue",
  size = "medium",
  className = "",
}) => {
  // La classe dynamique pour le bouton en fonction de la couleur et de la taille
  const buttonClass = `btn_app  btn_app-${color} btn_app-${size} ${className}`;

  return (
    <button className={buttonClass} onClick={onClick}>
      {text}
    </button>
  );
};

export default AppButton;
