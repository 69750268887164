import React, { useEffect, useLayoutEffect, useState } from "react";
import "./index.scss";

import { showErrorToaster } from "../../../components/toaster/error";

import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import DataTableDisplay from "../../../components/tableMission";
import CandidatureService from "../../../services/candidature/condidature.service";
import CandidatureResponsiveCards from "../candidatureCardResponsive";
const candidatureService = new CandidatureService();

function CandidatureListe() {
  const [candidature, setCandidature] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const loadData = async () => {
    setIsLoading(true);

    try {
      const res = await candidatureService?.getAllCandidature();
      setCandidature(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);
  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  function getCVURL(candidatureId) {
    return `${apiUrl}/candidatures/${candidatureId}/cv`;
  }

  const columnsCandidature = [
    {
      name: "Nom",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.name != null && row?.name}
        </div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Prénom",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.lastname != null && row?.lastname}
        </div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.email != null && row?.email}
        </div>
      ),

      sortable: true,
    },
    {
      name: "Télephone",
      selector: (row) => (
        <div className="table-text">{row?.phone != null && row?.phone}</div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Profile",
      selector: (row) => (
        <div className="table-text">{row?.profile != null && row?.profile}</div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Linkedin",
      selector: (row) => (
        <div className="table-text">
          {" "}
          <a href={row?.linkedin} target="_blank">
            {row?.linkedin != null && row?.linkedin}
          </a>
        </div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "  cv",
      selector: (row) => (
        <div className="table-text">
          {" "}
          <a href={getCVURL(row?.id)} target="_blank">
            {" "}
            {row?.cv != null && row?.cv}
          </a>
        </div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Lettre de motivation",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.description?.length > 15
            ? `${row?.lettreMotivation?.substring(0, 15)}...`
            : row?.lettreMotivation}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Mission",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.mission?.title != null && row?.mission?.title}
        </div>
      ),
      sortable: true,
    },
  ];
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3; // Adjust this number as needed

  // Calculate the offset
  const offset = currentPage * itemsPerPage;

  // Slice the missions list based on the current page and items per page
  const currentMissions = candidature.slice(offset, offset + itemsPerPage);

  // Handle page change
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (
    <section className="usersPage">
      <div className="container-fluid">
        <div className="row my-0 my-md-5 align-items-center">
          <div className="col-sm text-center text-sm-start">
            <h3>Candidatures liste</h3>
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex  my-0 my-md-5 justify-content-center align-items-center"></div>
        ) : (
          <div className=" my-0 my-md-5 py-2">
            {isMobile > 599 ? (
              <DataTableDisplay
                columns={columnsCandidature}
                data={candidature}
              />
            ) : (
              <>
                {candidature.map((item) => (
                  <CandidatureResponsiveCards
                    key={item.id} // Ensure the key is unique for each item
                    candidature={item}
                    loadData={loadData}
                  />
                ))}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(candidature.length / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default CandidatureListe;
