import axios from "../../config/axiosConfig";

export default class NewslettreService {
  addNewslettre = async (data) => {
    try {
      const response = axios.post("/newsletter/subscribe", data);
      return response.data;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la newsletter : " + error.message
      );
    }
  };
  getAllNewsletter = async () => {
    try {
      const response = axios.get("/newsletter/all");
      return response;
    } catch (error) {
      throw new Error("Erreur  : " + error.message);
    }
  };
}
