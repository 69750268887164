import React, { useState } from "react";
import { BsPerson } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF, FaInstagram } from "react-icons/fa6";
import logo from "../../assets/logofootr.svg";
import logom from "../../assets/logom.svg";
import NewslettreService from "../../services/newsletter/newslettre.service";
import { showErrorToaster } from "../toaster/error";
import { showSuccessToaster } from "../toaster/success";
import "./index.scss";
const newslettreService = new NewslettreService();
const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // Fonction de notification d'erreur

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérification de l'email
    if (!email) {
      setEmailError("Le champ email est obligatoire.");
      showErrorToaster("Le champ email est obligatoire.");
    } else if (!isValidEmail(email)) {
      setEmailError("Veuillez entrer un email valide.");
      showErrorToaster("Veuillez entrer un email valide.");
    } else {
      try {
        // Préparer les données à envoyer
        const data = { email };

        // Appeler la méthode du service pour ajouter la formation
        await newslettreService.addNewslettre(data);

        // Afficher le toast de succès
        showSuccessToaster("Inscription réussie à la newsletter");

        // Réinitialiser le champ et l'erreur
        setEmail("");
        setEmailError("");
      } catch (error) {
        // Afficher le toast d'erreur
        showErrorToaster(
          error.response
            ? error.response.data.message
            : "Une erreur est survenue, veuillez réessayer."
        );
      }
    }
  };
  return (
    <div className=" footer-section row  mx-0 justify-content-center  align-items-center ">
      <div className="col-11 col-md-7 mt-5">
        {" "}
        <img src={logo} alt="Image à gauche" className="w-100 h-100 logoWeb" />
        <img
          src={logom}
          alt="Image à gauche"
          className="w-100 h-100 logoMobile"
        />
      </div>
      <div className="email-section ">
        <form className="d-flex align-items-center  flex-row flex-column flex-md-row ">
          <div className="input-container position-relative">
            <span className="icon">
              <BsPerson />
            </span>
            <input
              value={email}
              onChange={handleEmailChange}
              type="email"
              className="form-control email-input"
              placeholder="Entrez votre email"
            />
          </div>
          <button
            type="submit"
            className="btn submit-button"
            onClick={handleSubmit}
          >
            Essayez maintenant
          </button>
        </form>
      </div>
      <div className="row justify-content-center  align-items-center">
        <nav className="navbar-section">
          <ul className="nav-links">
            <li>
              <a href="/">Accueil</a>
            </li>
            <li>
              <a href="/about">A propos</a>
            </li>
            <li>
              <a href="/service">Nos Services</a>
            </li>
            <li>
              <a href="/realisation ">Nos réalisations</a>
            </li>
            <li>
              <a href="/blog ">BLOG</a>
            </li>
            <li>
              <a href="/contact ">Contact</a>
            </li>
          </ul>
          <ul className="social-icons">
            <li>
              <a href="https://www.facebook.com/MSITConseil">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/msit.conseil/?igsh=N3FodTZrd2dlaGds&utm_source=qr#">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/msit-conseil/">
                <FaLinkedinIn />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="d-flex  justify-content-between  ">
        <p className="footer-bottom">
          &copy; {new Date().getFullYear()} MSIT Conseil. Tous droits réservés.
        </p>
        <p className="footer-bottom-links">
          <a href="/mentionsLégales " className="footer-bottom-link ">
            Mentions Légales
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
