import React, { useState, useEffect } from "react";
import "./index.scss";
import { showErrorToaster } from "../../../components/toaster/error";
import { useNavigate, useParams } from "react-router-dom";
import { showSuccessToaster } from "../../../components/toaster/success";
import RealisationService from "../../../services/realisation/realisation.service";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const realisationService = new RealisationService();

const RealisationForm = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Utiliser l'ID de la réalisation pour récupérer les données
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    image: "",
    slug: "",
  });
  const [imagePreview, setImagePreview] = useState(null); // Pour l'aperçu de l'image
  const [error, setError] = useState("");
  const [data, setJobList] = useState([]);
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };
  useEffect(() => {
    if (id) {
      // Si un ID est présent, on charge les données existantes pour l'édition
      const fetchRealisation = async () => {
        try {
          const response = await realisationService.getRealisationById(id);
          setFormData(response.data);
          setImagePreview(response.data.image); // Si une image existe, l'afficher
        } catch (error) {
          showErrorToaster("Erreur lors du chargement des données.");
        }
      };
      fetchRealisation();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Récupère le fichier sélectionné
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        image: file, // Met à jour l'image dans l'état
      }));
      setImagePreview(URL.createObjectURL(file)); // Met à jour l'aperçu de l'image
    }
  };
  const handleChange1 = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      description: value, // on met directement la valeur de l'éditeur dans "description"
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setJobList([...data, formData]);
    console.log(formData.slug);
    if (
      !formData.title ||
      !formData.description ||
      !formData.date ||
      !formData.image ||
      !formData.slug
    ) {
      setError("Veuillez remplir tous les champs.");
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("date", formData.date);
      formDataToSend.append("image", formData.image);
      formDataToSend.append("slug", formData.slug);
      if (id) {
        // Si un ID est présent, on modifie la réalisation existante
        await realisationService.updateRealisation(id, formDataToSend);
        showSuccessToaster("Modification réussie.");
      } else {
        // Sinon, on crée une nouvelle réalisation
        await realisationService.createRealisation(formDataToSend);
        showSuccessToaster("Création réussie.");
      }
      navigate("/realisation-liste");
    } catch (error) {
      showErrorToaster(
        error.response
          ? error.response.data.message
          : "Une erreur est survenue, veuillez réessayer."
      );
    }
  };

  return (
    <div className="job-form-container">
      <h1>{id ? "Modifier la réalisation" : "Créer une réalisation"}</h1>
      <form onSubmit={handleSubmit}>
        {error && <div className="error-message">{error}</div>}
        <div className="form-group">
          <label>Titre:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Slug:</label>
          <input
            type="slug"
            name="slug"
            value={formData.slug}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <ReactQuill
            value={formData.description}
            onChange={handleChange1}
            theme="snow"
            placeholder="Écris ton texte ici..."
            modules={modules}
            required
          />
        </div>
        <div className="form-group">
          <label>Date:</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Image:</label>
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
          />

          <p>
            {formData?.image
              ? formData.image.name
              : "Aucune image sélectionnée"}
          </p>
        </div>
        <button type="submit" className="submit-button">
          {id ? "Modifier" : "Créer"}
        </button>
      </form>
    </div>
  );
};

export default RealisationForm;
