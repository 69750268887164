import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import client1 from "../../assets/cl1.png";

import client2 from "../../assets/cl2.png";
import client3 from "../../assets/cl3.png";
import client4 from "../../assets/cl4.png";
import client5 from "../../assets/cl5.png";
import client6 from "../../assets/cl6.png";
import client7 from "../../assets/cl7.png";
import client8 from "../../assets/cl8.png";
import client9 from "../../assets/cl9.png";
import client10 from "../../assets/cl10.png";
import client11 from "../../assets/cl11.png";
import client12 from "../../assets/cl12.png";
import client13 from "../../assets/cl13.png";
import client14 from "../../assets/cl14.png";
import client15 from "../../assets/cl15.png";
import client16 from "../../assets/cl16.png";
import client17 from "../../assets/cl17.png";
import client18 from "../../assets/cl18.png";
import client19 from "../../assets/cl19.png";
import client20 from "../../assets/cl20.png";
import client21 from "../../assets/cl21.png";
import client22 from "../../assets/cl22.png";
import client23 from "../../assets/cl23.png";
import client24 from "../../assets/cl24.png";
import "./index.scss";
const Nosclients = () => {
  const settingsRow1 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 5, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false, // Direction de droite à gauche
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
  };
  const rows1 = [
    { id: 1, image: client1, alt: " brand 1" },
    { id: 2, image: client2, alt: " brand 2" },
    { id: 3, image: client3, alt: "brand 3" },
    { id: 4, image: client4, alt: "brand 4" },
    { id: 5, image: client5, alt: "brand 5" },
    { id: 6, image: client6, alt: " brand 1" },
    { id: 7, image: client7, alt: " brand 2" },
    { id: 8, image: client8, alt: "brand 3" },
    { id: 9, image: client9, alt: "brand 4" },
    { id: 10, image: client10, alt: "brand 5" },
    { id: 11, image: client11, alt: " brand 1" },
    { id: 12, image: client12, alt: " brand 2" },
    { id: 13, image: client13, alt: "brand 3" },
    { id: 14, image: client14, alt: "brand 4" },
    { id: 15, image: client15, alt: "brand 5" },
    { id: 16, image: client16, alt: " brand 1" },
    { id: 17, image: client17, alt: " brand 2" },
    { id: 18, image: client18, alt: "brand 3" },
    { id: 19, image: client19, alt: "brand 4" },
    { id: 20, image: client20, alt: "brand 5" },
    { id: 21, image: client21, alt: "brand 5" },
    { id: 22, image: client22, alt: "brand 5" },
    { id: 23, image: client23, alt: "brand 5" },
    { id: 24, image: client24, alt: "brand 5" },
  ];
  return (
    <>
      <div>
        <div className="row gx-0 w-100 justify-content-center align-items-center mt-5">
          <p className="serviceH-title mt-5 ">nos clients</p>{" "}
        </div>
        <div className="row ms-0  gx-0">
          <div className="col-12 col-md-8 mx-auto mt-5 mb-5">
            <Slider {...settingsRow1}>
              {rows1.map((row) => (
                <div key={row.id} className="row-slider3">
                  <img
                    src={row.image}
                    alt={row.alt}
                    className="image-hover-effect"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nosclients;
