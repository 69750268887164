import "./index.scss";
import React, { useEffect, useLayoutEffect, useState } from "react";

import AppButton from "../../../components/appButton";
import { showErrorToaster } from "../../../components/toaster/error";

import DataTableDisplay from "../../../components/tableMission";
import { useNavigate } from "react-router-dom";
import NewslettreService from "../../../services/newsletter/newslettre.service";

const newslettreService = new NewslettreService();

function NewslettreListe() {
  const [newsletters, setNewsletter] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    setIsLoading(true);

    try {
      const res = await newslettreService?.getAllNewsletter();
      setNewsletter(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const columnsNewsletters = [
    {
      name: "Email",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.email != null && row?.email}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <section className="usersPage">
      <div className="container-fluid">
        <div className="row  my-0 my-md-5 align-items-center">
          <div className="col-sm text-center text-sm-start">
            <h3>NewsLetters liste</h3>
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex my-5 justify-content-center align-items-center"></div>
        ) : (
          <div className=" my-0 my-md-5 py-2">
            <DataTableDisplay columns={columnsNewsletters} data={newsletters} />
          </div>
        )}
      </div>
    </section>
  );
}

export default NewslettreListe;
