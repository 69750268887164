import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./index.scss";
function CandidatureResponsiveCards({
  candidature,
  handleStatus,
  setAction,
  loadData,
  setSelectedId,
}) {
  const [plus, setPlus] = useState(false);

  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  function getCVURL(candidatureId) {
    return `${apiUrl}/candidatures/${candidatureId}/cv`;
  }

  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration (in milliseconds)
    });
    AOS.refresh();
  }, []);

  return (
    <div id="AbonnementResponsiveCards">
      <div className="card my-4">
        <div className="d-flex ps-3 mt-3 align-items-center ">
          <span className="title me-3">Nom et Prénom:</span>
          <span>
            {candidature?.name} {candidature?.lastname}
          </span>
        </div>
        <hr />
        <div className="d-flex ps-3 align-items-center ">
          <span className="title me-3">Email:</span>
          <span>{candidature?.email}</span>
        </div>{" "}
        <hr />
        <div className="d-flex ps-3 align-items-center ">
          <span className="title me-3"> Télephone:</span>
          <span>{candidature?.phone}</span>
        </div>{" "}
        <hr />
        <div className="d-flex ps-3 align-items-center ">
          <span className="title me-3">cv: </span>
          <span>
            <a href={getCVURL(candidature?.id)} target="_blank">
              {" "}
              {candidature?.cv != null && candidature?.cv}
            </a>
          </span>
        </div>{" "}
        <hr />
        <div className="d-flex ps-3 align-items-center ">
          <span className="title me-3">Mission: </span>
          <span>{candidature?.mission?.title}</span>
        </div>
        {plus && (
          <div data-aos="fade-down">
            <hr />
            <div className="d-flex ps-3 align-items-center ">
              <span className="title me-3"> Profile:</span>
              <span>{candidature?.profile}</span>
            </div>{" "}
            <hr />
            <div className="d-flex ps-3 align-items-center ">
              <span className="title me-3"> Linkedin:</span>
              <span>
                {" "}
                <a href={candidature?.linkedin} target="_blank">
                  {candidature?.linkedin}
                </a>
              </span>
            </div>{" "}
            <hr />
            <div className="d-flex ps-3 align-items-center ">
              <span className="title me-3">Lettre de motivation:</span>
              <span>{candidature?.lettreMotivation}</span>
            </div>
          </div>
        )}
        <hr className="mb-0" />
        <div className="d-flex align-items-center ps-3 ">
          <div className="d-flex justify-content-center align-items-center">
            <button
              onClick={() => setPlus(!plus)}
              className="btn btn-link"
              type="button"
            >
              <label className="mx-1">
                <IoEyeOutline style={{ fontSize: "20px" }} />
              </label>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CandidatureResponsiveCards;
