import React, { useState } from "react";
import ContactService from "../../services/contact/contact.service";
import "./index.scss"; // Lien vers votre fichier SCSS
import { showErrorToaster } from "../toaster/error";
import { showSuccessToaster } from "../toaster/success";
const contactService = new ContactService();
const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState();
  let label = {
    name: "Titre",
    lastname: "lastname",
    email: "Email",
    phone: "Phone",
    message: "Message",
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    let errors = {};
    if (!formData?.name || formData?.name === "") {
      error = true;
      errors[`name`] = "Champ obligatoire";
    }
    if (!formData?.lastname || formData?.lastname === "") {
      error = true;
      errors[`lastname`] = "Champ obligatoire";
    }
    if (!formData?.email || formData?.email === "") {
      error = true;
      errors[`email`] = "Champ obligatoire";
    }

    if (!formData?.phone || formData?.phone === "") {
      error = true;
      errors[`phone`] = "Champ obligatoire";
    }

    if (!formData?.message || formData?.message === "") {
      error = true;
      errors[`message`] = "Champ obligatoire";
    }
    setFormErrors(errors);
    if (error) {
      Object?.keys(errors)?.map((item) => {
        showErrorToaster(`${"champ"} ${label[item]} ${"est Obligatoire"} `);
      });
    } else {
      try {
        // Appeler la méthode du service pour ajouter la formation
        await contactService.addContact(formData);

        // Afficher le toast de succès
        showSuccessToaster("Inscription réussie au contact ");

        // Réinitialiser le champ et l'erreur
        setFormData({
          name: "",
          lastname: "",
          email: "",
          phone: "",
          message: "",
        });
      } catch (error) {
        // Afficher le toast d'erreur
        showErrorToaster(
          error.response
            ? error.response.data.message
            : "Une erreur est survenue, veuillez réessayer."
        );
      }
    }
  };
  return (
    <div className="contact-section d-flex flex-column justify-content-center align-items-center">
      <h1 className="contact-title">BONJOUR !</h1>
      <p className="contact-subtitle">
        N'hésitez pas à nous poser vos questions !
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Nom de famille"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <input
              type="text"
              id="lastname"
              className="form-control"
              placeholder="Prénom"
              value={formData.lastname}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-3">
          <input
            id="email"
            type="email"
            className="form-control"
            placeholder="E-mail"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <input
            id="phone"
            type="text"
            className="form-control"
            placeholder="Numéro de téléphone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <textarea
            id="message"
            className="form-control"
            rows="4"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default ContactSection;
