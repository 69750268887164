import React, { useEffect, useState } from "react";
import {
  BsBoxArrowRight,
  BsEnvelope,
  BsGrid,
  BsListTask,
  BsPeople
} from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showErrorToaster } from "../../../components/toaster/error";
import { showSuccessToaster } from "../../../components/toaster/success";
import UserService from "../../../services/user-auth/user.service";
import "./index.scss";

const userService = new UserService();

const Sidebar = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const [userID, setUserID] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const storedID = localStorage.getItem("userLoginRequestID");
    setUserID(storedID);
  }, []);

  const handleLogout = async () => {
    try {
      const data = { userId: userID };
      await userService.logout(data);
      showSuccessToaster("Déconnexion réussie");
      localStorage.removeItem("userLoginRequestID");
      navigate("/login");
    } catch (error) {
      showErrorToaster(
        error.response
          ? error.response.data.message
          : "Une erreur est survenue, veuillez réessayer."
      );
    }
  };

  return (
    <>
      <div className={`sidebar `}>
        <Link to={"/dashbord"}>
          <div
            className={`sidebar-item ${
              isActive("/dashbord") ? "active" : ""
            } martop`}
          >
            <BsGrid className="sidebar-icon" />
            <span>Tableau de bord</span>
          </div>
        </Link>
        <Link to={"/user-liste"}>
          <div
            className={`sidebar-item ${
              isActive("/user-liste") ? "active" : ""
            }`}
          >
            <BsPeople className="sidebar-icon" />
            <span>Liste des utilisateurs</span>
          </div>
        </Link>

        <Link to={"/mission-liste"}>
          <div
            className={`sidebar-item ${
              isActive("/mission-liste") ? "active" : ""
            }`}
          >
            <BsListTask className="sidebar-icon" />
            <span>Offres</span>
          </div>
        </Link>
        <Link to={"/candidature-liste"}>
          <div
            className={`sidebar-item ${
              isActive("/candidature-liste") ? "active" : ""
            }`}
          >
            <BsPeople className="sidebar-icon" />
            <span>Candidatures</span>
          </div>
        </Link>
        <Link to={"/realisation-liste"}>
          <div
            className={`sidebar-item ${
              isActive("/realisation-liste") ? "active" : ""
            }`}
          >
            <BsListTask className="sidebar-icon" />
            <span>Blogs</span>
          </div>
        </Link>
        <Link to={"/newsletter-liste"}>
          <div
            className={`sidebar-item ${
              isActive("/newsletter-liste") ? "active" : ""
            }`}
          >
            <BsEnvelope className="sidebar-icon" />
            <span>Emails NewsLetter</span>
          </div>
        </Link>
        <Link to={"/contact-liste"}>
          <div
            className={`sidebar-item ${
              isActive("/contact-liste") ? "active" : ""
            }`}
          >
            <BsListTask className="sidebar-icon" />
            <span>Contacts</span>
          </div>
        </Link>

        <div className="sidebar-item logout" onClick={handleLogout}>
          <BsBoxArrowRight className="sidebar-icon" />
          <span>Déconnexion</span>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
