import React, { useEffect, useState } from "react";
import "./index.scss";

import { showErrorToaster } from "../../../components/toaster/error";

import { useNavigate } from "react-router-dom";
import DataTableDisplay from "../../../components/tableMission";
import ContactService from "../../../services/contact/contact.service";

const contactService = new ContactService();

function ContactListe() {
  const [contacts, setContact] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    setIsLoading(true);

    try {
      const res = await contactService?.getAllContact();
      setContact(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const columnsContacts = [
    {
      name: "Nom",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.name != null && row?.name}
        </div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Prénom",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.lastname != null && row?.lastname}
        </div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.email != null && row?.email}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Télephone",
      selector: (row) => (
        <div className="table-text">{row?.phone != null && row?.phone}</div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => (
        <div className="table-text">{row?.message != null && row?.message}</div>
      ),
      hide: "md",
      sortable: true,
    },
  ];

  return (
    <section className="usersPage">
      <div className="container-fluid">
        <div className="row my-0 my-md-5 align-items-center">
          <div className="col-sm text-center text-sm-start">
            <h3>Contacts liste</h3>
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex my-0 my-md-5  justify-content-center align-items-center"></div>
        ) : (
          <div className="my-5 py-2">
            <DataTableDisplay columns={columnsContacts} data={contacts} />
          </div>
        )}
      </div>
    </section>
  );
}

export default ContactListe;
