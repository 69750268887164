import React, { useEffect, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import { FiCheckSquare } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import { BsBan } from "react-icons/bs";
function MissionResponsiveCards({
  mission,
  handleStatus,
  setAction,
  loadData,
  setSelectedId,
}) {
  const [plus, setPlus] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration (in milliseconds)
    });
    AOS.refresh();
  }, []);

  return (
    <div id="AbonnementResponsiveCards">
      <div className="card my-4">
        <div className="d-flex ps-3 mt-3 align-items-center ">
          <span className="title me-3">Titre:</span>
          <span>{mission?.title}</span>
        </div>
        <hr />
        <div className="d-flex ps-3 align-items-center ">
          <span className="title me-3">Pays:</span>
          <span>{mission?.paye}</span>
        </div>{" "}
        <hr />
        <div className="d-flex ps-3 align-items-center ">
          <span className="title me-3"> Duration:</span>
          <span>{mission?.duration}</span>
        </div>{" "}
        <hr />
        <div className="d-flex ps-3 align-items-center ">
          <span className="title me-3">Type Contrat: </span>
          <span>{mission?.typeContrat}</span>
        </div>{" "}
        <hr />
        <div className="d-flex ps-3 align-items-center ">
          <span className="title me-3">Status: </span>
          <span
            style={{
              padding: "5px 10px",
              borderRadius: "12px",
              color: mission?.status === true ? "#00AC0E" : "#EE0202",
              backgroundColor: mission?.status === true ? "#D2FFD6" : "#FFD8D8",
            }}
          >
            {mission?.status === true ? "Active" : "Inactive"}
          </span>
        </div>
        {plus && (
          <div data-aos="fade-down">
            <hr />
            <div className="d-flex ps-3 align-items-center ">
              <span className="title me-3">Description:</span>
              <span>
                <div
                  dangerouslySetInnerHTML={{ __html: mission?.description }}
                />
              </span>
            </div>
            <hr />
            <div className="d-flex ps-3 align-items-center ">
              <span className="title me-3">Niveau:</span>
              <span>{mission?.level}</span>
            </div>{" "}
            <hr />
            <div className="d-flex ps-3 align-items-center ">
              <span className="title me-3">Type de travail:</span>
              <span>{mission?.typeWork}</span>
            </div>{" "}
          </div>
        )}
        <hr className="mb-0" />
        <div className="d-flex align-items-center ps-3 ">
          <div className="d-flex justify-content-center align-items-center">
            <button
              onClick={() => setPlus(!plus)}
              className="btn btn-link"
              type="button"
            >
              <label className="mx-1">
                <IoEyeOutline style={{ fontSize: "20px" }} />
              </label>
            </button>

            <button
              type="button"
              onClick={() => {
                navigate(`/edit-mission/${mission?.id}`);
                window.scrollTo(0, 0);
              }}
              className="btn btn-link p-0"
            >
              <label className="mx-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-edit"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#545252"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                  <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                  <path d="M16 5l3 3" />
                </svg>
              </label>
            </button>

            <button
              className="btn btn-link"
              onClick={async () => {
                await handleStatus(mission?.id, mission?.status);
                await loadData();
              }}
              type="button"
            >
              {mission?.status === true ? (
                <label className="mx-1">
                  <BsBan style={{ color: "#EE0202" }} />
                </label>
              ) : (
                <label className="mx-1">
                  <FiCheckSquare style={{ color: "#00AC0E" }} />
                </label>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissionResponsiveCards;
