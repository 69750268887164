import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // Vérifiez si l'utilisateur est connecté (exemple : via localStorage ou un contexte global)
  const isAuthenticated = localStorage.getItem("userLoginRequestID");

  if (isAuthenticated == undefined || isAuthenticated == null) {
    return <Navigate to="/login" />;
  }

  // Sinon, affichez le composant enfant
  return children;
};

export default ProtectedRoute;
