import React, { useEffect, useRef, useState } from "react";
import AboutHome from "../../components/aboutHome";
import BrandHome from "../../components/brandHome";
import BrandHomeMobile from "../../components/brandHomeMoblie";
import Footer from "../../components/footer";
import SlideHome from "../../components/homeSlide";
import Nosclients from "../../components/nosclient";
import OurTeam from "../../components/ourTeam";
import RealisationHome from "../../components/realisationHome";
import ServiceHome from "../../components/serviceHome";
import { Helmet } from "react-helmet-async";
import video from "../../assets/loader.mp4";
import "./index.scss";

const HomePage = () => {
  const [showLoader, setShowLoader] = useState(true); // État pour le loader
  const videoRef = useRef(null); // Référence à l'élément vidéo

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    const expired = new Date() - new Date(hasVisited);

    const THIRTY_MINUTES = 30 * 60 * 1000;
    if (expired > THIRTY_MINUTES) {
      const timer = setTimeout(() => {
        setShowLoader(false); // Cacher le loader
        localStorage.setItem("hasVisited", new Date()); // Marque la visite comme terminée

        // Pause de la vidéo si elle est encore en cours
        if (videoRef.current) {
          videoRef.current.pause();
        }
      }, 3000); // Durée du loader : 3 secondes

      return () => clearTimeout(timer); // Nettoyage du timer
    }

    // Si déjà visité, masquer directement le loader

    setShowLoader(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>MSIT Conseil | Acceuil</title>
        <meta
          name="description"
          content="MSIT Conseil vous accompagne avec
expertise en Consulting, Développement
et Marketing. Nous créons des stratégies
efficaces pour faire évoluer votre
entreprise"
        />
      </Helmet>
      {showLoader ? (
        <div className="loader-container">
          <video
            id="video-loader"
            ref={videoRef}
            className="loader-video"
            autoPlay
            muted
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <div>
          <SlideHome />
          <AboutHome />
          <ServiceHome />
          <BrandHome />
          <BrandHomeMobile />
          <RealisationHome />

          {/*        <OurTeam />    */}
          <Nosclients />
          <Footer />
        </div>
      )}
    </>
  );
};

export default HomePage;
