import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import sm1 from "../../assets/ws1.png";
import sm2 from "../../assets/ws2.png";
import sm3 from "../../assets/ws3.png";
import sm4 from "../../assets/ws4.png";
import sm5 from "../../assets/ws5.png";
import sm6 from "../../assets/ws6.png";
import sm11 from "../../assets/wsr1.png";
import sm22 from "../../assets/wsr2.png";
import sm33 from "../../assets/wsr3.png";
import sm44 from "../../assets/wsr4.png";
import sm55 from "../../assets/wsr5.png";
import sm66 from "../../assets/wsr6.png";
import "./index.scss";
import sm7 from "../../assets/ws7.png";
import smr11 from "../../assets/wsr21.png";
import smr22 from "../../assets/wsr22.png";
import smr33 from "../../assets/wsr23.png";
import smr44 from "../../assets/wsr24.png";
const CustomSliderSw = () => {
  // Paramètres pour chaque slider
  const settingsRow1 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false, // Direction de droite à gauche
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsRow2 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: -1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: -1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: -1,
        },
      },
    ],
  };

  const settingsRow3 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
    // Direction de droite à gauche
  };
  const rows1 = [
    { id: 1, image: sm1, alt: " web site 1" },
    { id: 2, image: sm2, alt: " web site2" },
    { id: 3, image: sm3, alt: " web site 3" },
    { id: 4, image: sm4, alt: " web site 4" },
    { id: 5, image: sm5, alt: "  web site 5" },
    { id: 6, image: sm6, alt: "  web site6" },

    { id: 7, image: sm7, alt: "  web site7" },
  ];
  const rows2 = [
    { id: 1, image: sm11, alt: " web site1" },
    { id: 2, image: sm22, alt: " web site 2" },
    { id: 3, image: sm33, alt: " web site 3" },
    { id: 4, image: sm44, alt: " web site4" },
    { id: 5, image: sm55, alt: "  web site 5" },
    { id: 6, image: sm66, alt: "  web site 6" },
  ];
  const rows3 = [
    { id: 1, image: smr11, alt: " web site 1" },
    { id: 2, image: smr22, alt: "  web site 2" },
    { id: 3, image: smr33, alt: " web site3" },
    { id: 4, image: smr44, alt: " web site 4" },
  ];

  return (
    <div className="margintop">
      <div className="slider-row">
        <Slider {...settingsRow1}>
          {rows1.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider-row">
        <Slider {...settingsRow2}>
          {rows2.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider-row">
        <Slider {...settingsRow3}>
          {rows3.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CustomSliderSw;
