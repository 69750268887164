import React from "react";

import "./index.scss";
import Sidebar from "../sidebar";
import Header from "../headerAdmin";

const AppLayoutAdmin = ({ children }) => {
  return (
    <div className="app-layout-admin">
      <Sidebar />
      <div className="main-content-admin">
        <Header />
        <div className="children-content-admin">{children}</div>
      </div>
    </div>
  );
};

export default AppLayoutAdmin;
