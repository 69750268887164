import { motion } from "framer-motion";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import about from "../../assets/aboutN.svg";
import aboutm from "../../assets/aboutNm.svg";
import bal from "../../assets/balA.svg";
import creative from "../../assets/Creative.svg";
import creative1 from "../../assets/Creative1.svg";
import creative1m from "../../assets/creative2m.svg";
import creativem from "../../assets/creativem.svg";
import "./index.scss";
const AboutPage = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/contact");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  return (
    <>
      <Helmet>
        <title>A propos - MSIT Conseil</title>
        <meta
          name="description"
          content="Découvrez MSIT Conseil, votre
partenaire de conseil idéal basé. en
France. Nous offerons des solutions sur
mesure pour optimiser vos décisions
stratégiques."
        />
      </Helmet>
      <section className="about-section container1">
        <div className="about-title text-center w-100 mb-5 position-relative">
          <img
            src={about}
            alt="Image à droite"
            className="about-img img-fluid w-100 aboutWeb"
          />
          <img
            src={aboutm}
            alt="Image à droite"
            className="about-img img-fluid w-100 aboutMobile"
          />
          {/*   <button className="watch-video-button">
            <span className="text">WATCH VIDEO</span>
            <div className="icon-wrapper">
              <BsArrowUpRight className="icon" />
            </div>
          </button> */}
        </div>
        <div className="row">
          <p className="about-description  mt-3">
            Msit Conseil est un cabinet de conseil reconnu à l’échelle
            internationale basé en France et fournissant des solutions efficaces
            à de nombreuses entreprises partout dans le monde. Nous accompagnons
            nos clients et mettons à leurs disposition tout notre savoir-faire
            afin de les aider dans leurs processus de prise de décision. Grâce à
            nos connaissances en matière de Business Intelligence et de Big
            Data, nos consultants apportent des solutions à vos problématiques.
            Nos collaborateurs sont à l’écoute permanente de nos clients,
            développant ainsi une proximité et un engagement quotidien avec nos
            clients.
          </p>
        </div>
      </section>
      <div className="row  gx-0 justify-content-center  align-items-center ">
        <div className="col-6">
          <motion.img
            src={bal} // Remplacez par l'URL ou le chemin de votre image
            alt="Image flottante"
            animate={{
              y: [0, -20, 0], // Déplacement vertical (flottement)
            }}
            transition={{
              duration: 2, // Durée d'un cycle de flottement
              repeat: Infinity, // Répéter l'animation indéfiniment
              repeatType: "loop", // Répéter en boucle
              ease: "easeInOut", // Type d'easing
            }}
            className="balimgA"
            style={{ width: "auto", height: "auto", marginTop: "-70px" }}
          />
        </div>
        <div className="col-6 d-flex  justify-content-end mt-md-0 mt-5 ">
          {" "}
          <img
            src={creative}
            alt="Image à droite"
            className=" img-fluid sizeW"
          />
          <img src={creativem} alt="Image à droite" className=" sizeM" />
        </div>
        <div className="row  gx-0 justify-content-center  align-items-center ">
          <div className="col-12 col-md-7 d-flex justify-content-center  align-items-center ">
            <p className="text-pA">
              Nous aimerions en savoir plus sur votre projet
            </p>
          </div>
          <div className="col-12 col-md-5 marginMobile">
            <button
              class="glow-on-hover"
              type="button"
              onClick={handleNavigation}
            >
              Contactez-nous
            </button>
          </div>
        </div>
      </div>
      <div className="row gx-0 mt-5 align-items-center  ">
        <div className="col-7 mt-5 mb-5">
          {" "}
          <img
            src={creative1}
            alt="Image à droite"
            className=" img-fluid sizeW"
          />
          <img src={creative1m} alt="Image à droite" className=" sizeM" />
        </div>
      </div>
    </>
  );
};

export default AboutPage;
