import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import cons3 from "../../assets/c4.svg";
import cons1 from "../../assets/consf1.png";
import cons5 from "../../assets/consf2.png";
import cons2 from "../../assets/consf3.png";
import cons6 from "../../assets/consf4.png";
import cons from "../../assets/CONSULLTING.png";
import "./index.scss";
import { Helmet } from "react-helmet-async";

import gif from "../../assets/GIFF.svg";
const ConsultingPage = () => {
  return (
    <>
      <Helmet>
        <title>Consulting - MSIT Conseil</title>
        <meta
          name="description"
          content="MSIT Conseil, votre partenaire de
confiance pour des solutions de
consulting. Nous optimisons vos
processus, renforçons vos compétences
et maximisons votre croissance."
        />
      </Helmet>
      <div className="container py-5 complex-section">
        <div className="cons-title text-center mb-5">
          {" "}
          <img src={cons} alt="Image à droite" className="cons-img img-fluid" />
        </div>
        <p className="cons-description  mb-5">
          Bienvenue chez Msit Conseil, votre partenaire de confiance pour des
          solutions de consulting innovantes et sur mesure. Nous accompagnons
          les entreprises dans la réalisation de leurs projets stratégiques et
          technologiques, grâce à une expertise à la pointe des tendances
          actuelles.
          <br />
          <span>Notre objectif :</span> optimiser vos processus, renforcer vos
          compétences et maximiser votre croissance.
        </p>

        {/* Première ligne */}
        <div className="row gx-3 mtop gy-4">
          {/* Première colonne de la première ligne */}
          <div className="col-md-8">
            {/* Deux rangées dans la première colonne */}
            <div className="row gx-3  gy-4">
              <div className="col-12  ">
                <div className=" row body-cons  custom-card g-0 ">
                  <div className="col-5 ">
                    <img src={cons1} className="img-fluid w-100 h-100" />
                  </div>
                  <div className=" col-7 cons-padding text-white">
                    <h5 className="card-title">MOA/MOE/QA</h5>
                    <ul>
                      <li>Banque/Finance</li>
                      <li>E-Commerce</li>
                      <li>Assurance</li>
                      <li>Logistique</li>
                      <li>Automobile</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className=" row mx-0 body-cons     custom-card g-0 ">
                  <div className=" col-7 left-card1 position-relative h-100">
                    <div className=" row mx-0   w-100  cons-padding   text-white">
                      <h5 className="card-title">DÉCISIONNEL</h5>
                      <div className="col-6 ">
                        <ul className="pad-mob-cons">
                          <li>Informatica</li>
                          <li>Datastage</li>
                          <li>Talend</li>
                          <li>MSBI</li>
                        </ul>
                      </div>

                      <div className="col-6 ">
                        <ul className="pad-mob-cons">
                          <li>Power BI</li>
                          <li>Cognos/BO</li>
                          <li>BGDS</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-5  d-flex flex-column align-items-end">
                    <img src={cons2} className="img-fluid w-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Deuxième colonne de la première ligne */}
          <div className="col-md-4">
            <div className="card custom-card">
              <div className="custom-card1 position-relative h-100">
                {/* Image en haut */}
                <div className="image-container">
                  <img
                    src={cons5}
                    className=" card-image img-fluid w-100 h-100" // Remplacez par le chemin de votre image
                    alt="Tech Tree"
                  />
                </div>

                {/* Sous-carte */}
                <div className="overlay-card position-absolute">
                  <div className="card-content mt-0 mt-md-5">
                    <h5 className="card-title">NOUVELLES TECHNOLOGIES</h5>
                    <ul>
                      <li>Java,JavajEE</li>
                      <li>Dot Net</li>
                      <li>C sharp</li>
                      <li>Php</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Deuxième ligne */}
        <div className="row gx-3 gy-3 mt-3">
          {/* Deuxième colonne de la deuxième ligne */}

          {/* First column */}

          {/* Second column */}
          <div className="col-md-6 position-relative">
            <div className="body-cons   w-100  custom-card g-0 position-relative h-100 custom-card">
              {/* Image */}
              <img
                src={cons6}
                className="img-fluid h-100"
                alt="Infrastructure"
              />
              {/* Overlapping card */}
              <div
                className="position-absolute d-flex flex-column justify-content-center align-items-start text-white p-3 card "
                style={{
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: "65%",
                  backgroundColor: "#002b58",
                  zIndex: 2,
                }}
              >
                <h5 className="card-title"> INFRASTRUCTURE / EXPLOITATION</h5>
                <ul>
                  <li>Ingénieur production DollarU/Control</li>
                  <li>Administrateur système Unix/Linux</li>
                  <li>DBA Prod/Étude</li>
                  <li>DBA Windows/Linux</li>
                  <li>DevOps</li>
                </ul>
              </div>
            </div>
          </div>
          {/* Première colonne de la deuxième ligne */}
          <div className="col-md-6">
            <div className="card custom-card">
              <div className="custom-card1 position-relative h-100">
                {/* Image en haut */}
                <div className="image-container w-100">
                  <img
                    src={cons3}
                    className=" card-image img-fluid w-100 h-100" // Remplacez par le chemin de votre image
                    alt="Tech Tree"
                  />
                </div>

                {/* Sous-carte */}
                <div className="overlay-card1  d-flex  justify-content-center position-absolute">
                  <div className=" row  card-content  w-100 align-items-center justify-content-center">
                    <div className="col-6 ">
                      <h5 className="card-title"> BIG DATA </h5>
                    </div>
                    <div className="col-6 ">
                      {" "}
                      <ul>
                        <li>Hadoop</li>
                        <li>Spark Scala</li>
                        <li>Kafka</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="  row h-auto section-conf align-items-stretch gx-0">
        {/* Left Column */}
        <div className="col-12 col-md-4 h-auto">
          <h2 className="title-conf">Faites nous confiance</h2>
        </div>

        {/* Right Column */}
        <div className="col-12 col-md-8 d-flex  h-auto">
          <p className="text-conf">
            <img
              src={gif}
              alt="Image à droite"
              className="cons-img margin-img-cons  "
            />
          </p>
        </div>
      </div>
    </>
  );
};
export default ConsultingPage;
