import React, { useState, useEffect } from "react";
import "./index.scss";
import MissionService from "../../../services/mission/mission.service";
import { showErrorToaster } from "../../../components/toaster/error";
import { useNavigate, useParams } from "react-router-dom";
import { showSuccessToaster } from "../../../components/toaster/success";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const missionService = new MissionService();

const JobForm = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Récupère l'identifiant depuis l'URL pour l'édition
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    paye: "",

    duration: 0,
    typeContrat: "",
    level: "",
    typeWork: "",
  });
  const [error, setError] = useState("");
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };
  // Charger les données de l'emploi en cas d'édition
  useEffect(() => {
    if (id) {
      // Si un identifiant est présent, nous sommes en mode "édition"
      const fetchMissionData = async () => {
        try {
          const response = await missionService.getMissionById(id);
          setFormData(response.data); // Remplit le formulaire avec les données existantes
        } catch (error) {
          showErrorToaster(
            error.response
              ? error.response.data.message
              : "Erreur lors du chargement des données."
          );
        }
      };
      fetchMissionData();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange1 = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      description: value, // on met directement la valeur de l'éditeur dans "description"
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.title || !formData.description) {
      setError("Veuillez remplir tous les champs.");
      return;
    }

    try {
      if (id) {
        // Mode édition : Mettre à jour l'emploi existant
        await missionService.updateMission(id, formData);
        showSuccessToaster("Mise à jour réussie.");
      } else {
        // Mode création : Ajouter un nouvel emploi
        await missionService.addMission(formData);
        showSuccessToaster("Création réussie.");
      }
      navigate("/mission-liste");
    } catch (error) {
      showErrorToaster(
        error.response
          ? error.response.data.message
          : "Une erreur est survenue, veuillez réessayer."
      );
    }
  };

  return (
    <div className="job-form-container">
      <h1>{id ? "Modifier l'emploi" : "Créer un emploi"}</h1>
      <form onSubmit={handleSubmit}>
        {error && <div className="error-message">{error}</div>}
        <div className="form-group">
          <label>Titre:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Description:</label>

          <ReactQuill
            value={formData.description}
            onChange={handleChange1}
            theme="snow"
            placeholder="Écris ton texte ici..."
            modules={modules}
            required
          />
        </div>
        <div className="form-group">
          <label>Pays :</label>
          <input
            type="text"
            name="paye"
            value={formData.paye}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>duration:</label>
          <input
            type="number"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Type de contrat:</label>
          <input
            type="text"
            name="typeContrat"
            value={formData.typeContrat}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Niveau:</label>
          <input
            type="text"
            name="level"
            value={formData.level}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Type de travail:</label>
          <input
            type="text"
            name="typeWork"
            value={formData.typeWork}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="submit-button">
          {id ? "Mettre à jour" : "Créer"}
        </button>
      </form>
    </div>
  );
};

export default JobForm;
