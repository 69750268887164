import axios from "../../config/axiosConfig";

export default class RealisationService {
  getAllActiveRealisation = async () => {
    try {
      const response = axios.get("realisation/active/realisation");
      return response;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la contact : " + error.message
      );
    }
  };
  getAllRealisation = async () => {
    try {
      const response = axios.get("realisation");
      return response;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la contact : " + error.message
      );
    }
  };
  createRealisation = async (data) => {
    try {
      const response = axios.post("/realisation", data);
      return response.data;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la mission : " + error.message
      );
    }
  };
  toggleRStatus = async (id, data) => {
    try {
      const response = axios.patch(`/realisation/${id}/status`, data);
      return response.data;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la mission : " + error.message
      );
    }
  };
  getRealisationById = async (id) => {
    try {
      const response = axios.get(`/realisation/${id}`);
      return response;
    } catch (error) {
      throw new Error("Erreur  : " + error.message);
    }
  };
  getRealisationBySlug = async (slug) => {
    try {
      const response = axios.get(`/realisation/${slug}/blog`);
      return response;
    } catch (error) {
      throw new Error("Erreur  : " + error.message);
    }
  };
  updateRealisation = async (id, data) => {
    try {
      const response = axios.put(`/realisation/${id}`, data);
      return response.data;
    } catch (error) {
      throw new Error(
        "Erreur lors de l' edit de la mission : " + error.message
      );
    }
  };
}
