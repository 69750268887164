import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logos.svg";
import "./index.scss"; // Import du fichier SCSS
const Header = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname); // Initialise avec la route actuelle
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownM, setShowDropdownM] = useState(false);
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768); // Mobile : ≤ 768px
    };
    handleResize(); // Vérifie la taille au chargement
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
    setShowDropdownM(false);
  };
  const navVariants = {
    hidden: { opacity: 0, x: 100 }, // Départ : Hors écran à droite
    visible: {
      opacity: 1,
      x: 0, // Fin : À sa position d'origine
      transition: { duration: 1, ease: "easeOut" }, // Durée et effet
    },
  };
  return (
    <header className="header-container ">
      <div className="container w-100 d-flex justify-content-between align-items-center">
        {/* Logo Section */}
        <div className="logo ">
          <img
            src={logo} // Remplace par le chemin de ton logo
            alt="Logo"
            className="logo-img"
            onClick={handleNavigation}
          />
        </div>
        {isMobile ? (
          <button onClick={toggleModal} className="icon-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 6H20V8H4V6ZM7 11H20V13H7V11ZM20 16H4V18H20V16Z"
                fill="white"
              />
            </svg>
          </button>
        ) : (
          <nav className="nav-links">
            <a
              href="/"
              className={`nav-link  ${activeMenu === "/" ? "active" : ""}`}
            >
              Accueil
            </a>
            <a
              href="/about"
              className={`nav-link  ${activeMenu === "/about" ? "active" : ""}`}
            >
              A propos
            </a>

            <div
              className="nav-link dropdown-container"
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              <a
                href="/service"
                className={`nav-link ${
                  activeMenu === "/service" ? "active" : ""
                }`}
                onClick={() => setActiveMenu("/service")}
              >
                Nos Services <BsChevronDown />
              </a>
              {showDropdown && (
                <div className="dropdown-menu1">
                  <a href="/consulting" className="dropdown-item1">
                    Consulting
                  </a>
                  <a href="/developpemnt" className="dropdown-item1">
                    Développement
                  </a>
                  <a href="/design" className="dropdown-item1">
                    Design
                  </a>
                  <a href="/marketing" className="dropdown-item1">
                    Marketing
                  </a>
                </div>
              )}
            </div>
            <a
              href="/realisation"
              className={`nav-link  ${
                activeMenu === "/realisation" ? "active" : ""
              }`}
            >
              Nos Réalisations
            </a>
            <a
              href="/blog"
              className={`nav-link  ${activeMenu === "/blog" ? "active" : ""}`}
            >
              Blog
            </a>

            <a
              href="/rejoignez-nous"
              className={`nav-link  ${
                activeMenu === "/rejoignez-nous" ? "active" : ""
              }`}
            >
              Rejoignez nous
            </a>
            <a
              href="/contact"
              className={`nav-link  ${
                activeMenu === "/contact" ? "active" : ""
              }`}
            >
              Contact
            </a>
          </nav>
        )}
        {/* Navigation Links */}
      </div>
      <Modal show={showModal} onHide={toggleModal} dialogClassName="fade-right">
        <Modal.Body>
          {" "}
          <motion.nav
            className="nav-links d-flex justify-content-center align-items-end mt-5"
            initial="hidden" // État initial
            animate="visible" // État final
            variants={navVariants} // Définit les animations
          >
            <a href="/" className="nav-link nav-m">
              Accueil
            </a>
            <a href="/about" className="nav-link nav-m">
              A propos
            </a>
            <div
              className=" nav-link nav-m"
              onMouseEnter={() => setShowDropdownM(true)}
              onMouseLeave={() => setShowDropdownM(false)}
            >
              <a href="/service" className="nav-m1">
                Nos Services
                <BsChevronDown />
              </a>
              {showDropdownM && (
                <div className="">
                  <a href="/consulting" className="nav-link nav-m">
                    Consulting
                  </a>
                  <a href="/developpemnt" className="nav-link nav-m">
                    Développement
                  </a>
                  <a href="/design" className="nav-link nav-m">
                    Design
                  </a>
                  <a href="/marketing" className="nav-link nav-m">
                    Marketing
                  </a>
                </div>
              )}
            </div>
            <a href="/realisation" className="nav-link nav-m">
              Nos Réalisations
            </a>
            <a href="/blog" className="nav-link nav-m">
              Blog
            </a>
            <a href="/rejoignez-nous" className="nav-link nav-m">
              Rejoignez nous
            </a>{" "}
            <a href="/contact" className="nav-link nav-m">
              Contact
            </a>
          </motion.nav>
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default Header;
