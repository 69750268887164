import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import brandh from "../../assets/bgb.svg";
import b1 from "../../assets/br11.png";
import b2 from "../../assets/br2.png";
import b3 from "../../assets/br3.png";
import b4 from "../../assets/br4.png";
import phone2 from "../../assets/phones.svg";
import "./index.scss";

const BrandHome = () => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [isMoved, setIsMoved] = useState(false); // Etat pour déplacer l'image
  const [isThirdClick, setIsThirdClick] = useState(false); // Etat pour le 3ème clic
  const [opacity, setOpacity] = useState(false);
  const handleClick = () => {
    if (!isZoomed) {
      setIsZoomed(true);
      // Zoom in lors du premier clic
    } else if (!isMoved) {
      setIsMoved(true); // Déplacement de l'image et changement de fond après le premier clic
    } else {
      setIsThirdClick(true); // Effectuer le mouvement vers la gauche et disparition lors du 3ème clic
    }
  };
  const handleAnimationComplete = () => {
    if (isZoomed) {
      // Lorsque le zoom est terminé, mettre l'opacité à 0
      setOpacity(true);
    }
  };

  return (
    <motion.div
      style={{
        position: "relative",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#252424",
        cursor: `url(${require("../../assets/cursor.png")}), auto`,
      }}
      className="dispbrandweb "
      animate={{ backgroundColor: isMoved ? "#CCC" : "#252424" }}
      transition={{
        duration: 1.5,
        ease: "easeInOut",
      }}
      onClick={handleClick}
    >
      {/* Image animée */}
      <motion.img
        src={brandh}
        alt="Grande Image"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          zIndex: 100,
          borderRadius: isZoomed ? "45px" : "0px",
          opacity: opacity ? 0 : 1,
        }}
        animate={{
          width: isZoomed ? "310px" : "100%",
          height: isZoomed ? "670px" : "100%",
          top: isZoomed ? "130px" : "0px",
          left: isZoomed ? "47%" : "0px",
        }}
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
        onClick={handleClick}
        onAnimationComplete={handleAnimationComplete} // Callback pour l'animation
      />

      {/* Cadre central */}
      <motion.div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        animate={{
          x: isThirdClick ? "-100%" : isMoved ? "-24%" : "0",
        }}
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
      >
        <img src={phone2} alt="Image à droite" className="img-fluid" />
      </motion.div>

      {/* Carte à droite (apparaît après le 2e clic) */}
      {isMoved && (
        <motion.div
          style={{
            position: "absolute",
            top: "13%",
            right: "-100%",
            backgroundColor: "transparent",
            borderRadius: "10px",
            width: "60%",
            opacity: 0,
          }}
          onClick={handleClick}
          animate={{
            right: isThirdClick ? "20%" : isMoved ? "-15%" : "-100%",
            opacity: isMoved ? 1 : 0,
          }}
          transition={{
            duration: 1.5,
            ease: "easeInOut",
          }}
        >
          <div className="row w-100 gx-0 position-relative">
            {/* Section 1 */}
            <motion.p
              className="title-overlay"
              animate={{
                opacity: isThirdClick ? 1 : 0,
              }}
              transition={{
                duration: 1.5,
                ease: "easeInOut",
              }}
            >
              {" "}
              Pourquoi<span className="span-overlay">
                {" "}
                Nous Choisir ?{" "}
              </span>{" "}
            </motion.p>
            <motion.div
              className="overlay"
              animate={{
                opacity: isThirdClick ? 0 : 1,
                zIndex: isThirdClick ? 100000000000000000000000000 : 1,
              }}
              transition={{
                duration: 1.5,
                ease: "easeInOut",
              }}
            >
              <div className="social-media">
                <div className="icons">
                  <p>Consultez nos réseaux</p>
                  <a href="https://www.facebook.com/MSITConseil">
                    {" "}
                    <FaFacebookF className="icon" />
                  </a>
                  <a href="https://www.instagram.com/msit.conseil/?igsh=N3FodTZrd2dlaGds&utm_source=qr#">
                    <FaInstagram className="icon" />
                  </a>
                  <a href="https://www.linkedin.com/company/msit-conseil/">
                    <FaLinkedin className="icon" />
                  </a>
                </div>
              </div>
            </motion.div>

            {/* Section avec les cartes */}
            <div
              className={`row w-100 justify-content-center align-items-stretch h-100 mb-4 `}
            >
              <div className="col-8">
                <div
                  className="cardb"
                  style={{ backgroundImage: `url(${b1})` }}
                >
                  <div className="card-contentb">
                    <h2>Comprehensive Digital Expertise</h2>
                    <p>
                      Our team possesses deep expertise across all facets of the
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="cardb"
                  style={{ backgroundImage: `url(${b2})` }}
                >
                  <div className="card-contentb">
                    <h2>Comprehensive Digital Expertise</h2>
                    <p>
                      Our team possesses deep expertise across all facets of the
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Section avec opacité */}
            <div className="row w-100 justify-content-center align-items-stretch h-100 mb-4">
              <div className="col-4">
                <div
                  className="cardb"
                  style={{ backgroundImage: `url(${b3})` }}
                >
                  <div className="card-contentb">
                    <h2>Comprehensive Digital Expertise</h2>
                    <p>
                      Our team possesses deep expertise across all facets of the
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div
                  className="cardb"
                  style={{ backgroundImage: `url(${b4})` }}
                >
                  <div className="card-contentb">
                    <h2>Comprehensive Digital Expertise</h2>
                    <p>
                      Our team possesses deep expertise across all facets of the
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default BrandHome;
