import { motion } from "framer-motion";
import React from "react";
import bal from "../../assets/bal.svg";
import realisation from "../../assets/realisation.png";
import Tabs from "../../components/tabs";
import "./index.scss";
import { Helmet } from "react-helmet-async";
const RealisationPage = () => {
  return (
    <>
      <Helmet>
        <title>Réalisations - MSIT Conseil</title>
        <meta
          name="description"
          content="Découvrez le portfolio de MSIT Conseil :
des réalisations exceptionnelles qui
boostent la visibilité et les performances
de nos clients. Laissez-vous inspirer!"
        />
      </Helmet>
      <section className="realisation-section container">
        <div className="realisation-title text-center  mb-md-5 mb-3 ">
          {" "}
          <img
            src={realisation}
            alt="Image à droite"
            className="realisation-img img-fluid w-100"
          />
        </div>
        <p className="realisation-description   mb-md-5 mb-3">
          Prenez une dose de nos pilules de stratégie, de branding, de marketing
          et de production de contenu et regardez votre marque devenir la
          meilleure version d'elle-même.
        </p>
      </section>
      <Tabs />
      <motion.img
        src={bal} // Remplacez par l'URL ou le chemin de votre image
        alt="Image flottante"
        animate={{
          y: [0, -20, 0], // Déplacement vertical (flottement)
        }}
        transition={{
          duration: 2, // Durée d'un cycle de flottement
          repeat: Infinity, // Répéter l'animation indéfiniment
          repeatType: "loop", // Répéter en boucle
          ease: "easeInOut", // Type d'easing
        }}
        style={{ width: "200px", height: "auto" }}
      />
    </>
  );
};

export default RealisationPage;
