import axios from "../../config/axiosConfig";

export default class MissionService {
  getAllActiveMission = async () => {
    try {
      const response = axios.get("/missions/active/mission");
      return response;
    } catch (error) {
      throw new Error("Erreur  : " + error.message);
    }
  };
  getAllMission = async () => {
    try {
      const response = axios.get("/missions");
      return response;
    } catch (error) {
      throw new Error("Erreur  : " + error.message);
    }
  };
  getMissionById = async (id) => {
    try {
      const response = axios.get(`/missions/${id}`);
      return response;
    } catch (error) {
      throw new Error("Erreur  : " + error.message);
    }
  };
  addMission = async (data) => {
    try {
      const response = axios.post("/missions", data);
      return response.data;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la mission : " + error.message
      );
    }
  };
  updateMission = async (id, data) => {
    try {
      const response = axios.patch(`/missions/${id}`, data);
      return response.data;
    } catch (error) {
      throw new Error(
        "Erreur lors de l' edit de la mission : " + error.message
      );
    }
  };
  toggleMStatus = async (id, data) => {
    try {
      const response = axios.patch(`/missions/${id}/status`, data);
      return response.data;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la mission : " + error.message
      );
    }
  };
}
