import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import sm1 from "../../assets/sm1.png";
import sm10 from "../../assets/sm10.png";
import sm11 from "../../assets/sm11.png";
import sm12 from "../../assets/sm12.png";
import sm13 from "../../assets/sm13.png";
import sm2 from "../../assets/sm2.png";
import sm3 from "../../assets/sm3.png";
import sm4 from "../../assets/sm4.png";
import sm5 from "../../assets/sm5.png";
import sm6 from "../../assets/sm6.png";
import sm7 from "../../assets/sm7.png";
import sm8 from "../../assets/sm8.png";
import sm9 from "../../assets/sm9.png";
import smr1 from "../../assets/smr1.png";
import smr10 from "../../assets/smr10.png";
import smr11 from "../../assets/smr11.png";
import smr2 from "../../assets/smr2.png";
import smr3 from "../../assets/smr3.png";
import smr4 from "../../assets/smr4.png";
import smr5 from "../../assets/smr5.png";
import smr6 from "../../assets/smr6.png";
import smr7 from "../../assets/smr7.png";
import smr8 from "../../assets/smr8.png";
import smr9 from "../../assets/smr9.png";
import smrr1 from "../../assets/smrr1.png";
import smrr10 from "../../assets/smrr10.png";
import smrr11 from "../../assets/smrr11.png";
import smrr12 from "../../assets/smrr12.png";
import smrr2 from "../../assets/smrr2.png";
import smrr3 from "../../assets/smrr3.png";
import smrr4 from "../../assets/smrr4.png";
import smrr5 from "../../assets/smrr5.png";
import smrr6 from "../../assets/smrr6.png";
import smrr7 from "../../assets/smrr7.png";
import smrr8 from "../../assets/smrr8.png";
import smrr13 from "../../assets/smrr13.png";

import "./index.scss";
const CustomSliderSm = () => {
  // Paramètres pour chaque slider
  const settingsRow1 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false, // Direction de droite à gauche
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsRow2 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: -1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: -1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: -1,
        },
      },
    ],
  };

  const settingsRow3 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
    // Direction de droite à gauche
  };
  const rows1 = [
    { id: 1, image: sm1, alt: " social media 1" },
    { id: 2, image: sm2, alt: " social media 2" },
    { id: 3, image: sm3, alt: "social media 3" },
    { id: 4, image: sm4, alt: "social media 4" },
    { id: 5, image: sm5, alt: " social media 5" },
    { id: 6, image: sm6, alt: " social media 6" },
    { id: 7, image: sm7, alt: "social media 7" },
    { id: 8, image: sm8, alt: "social media 8" },
    { id: 9, image: sm9, alt: " social media 9" },
    { id: 10, image: sm10, alt: " social media 10" },
    { id: 11, image: sm11, alt: "social media 11" },
    { id: 12, image: sm12, alt: "social media 12" },
    { id: 13, image: sm13, alt: "social media 13" },
  ];
  const rows2 = [
    { id: 1, image: smr1, alt: " social media 1" },
    { id: 2, image: smr2, alt: " social media 2" },
    { id: 3, image: smr3, alt: "social media 3" },
    { id: 4, image: smr4, alt: "social media 4" },
    { id: 5, image: smr5, alt: " social media 5" },
    { id: 6, image: smr6, alt: " social media 6" },
    { id: 7, image: smr7, alt: "social media 7" },
    { id: 8, image: smr8, alt: "social media 8" },
    { id: 9, image: smr9, alt: " social media 9" },
    { id: 10, image: smr10, alt: " social media 10" },
    { id: 11, image: smr11, alt: "social media 11" },
  ];
  const rows3 = [
    { id: 1, image: smrr1, alt: " social media 1" },
    { id: 2, image: smrr2, alt: " social media 2" },
    { id: 3, image: smrr3, alt: "social media 3" },
    { id: 4, image: smrr4, alt: "social media 4" },
    { id: 5, image: smrr5, alt: " social media 5" },
    { id: 6, image: smrr6, alt: " social media 6" },
    { id: 7, image: smrr7, alt: "social media 7" },

    { id: 8, image: smrr10, alt: " social media 10" },
    { id: 9, image: smrr11, alt: "social media 11" },
    { id: 10, image: smrr12, alt: "social media 12" },
    { id: 11, image: smrr13, alt: "social media 13" },
  ];

  return (
    <div className="margintop">
      <div className="slider-row">
        <Slider {...settingsRow1}>
          {rows1.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider-row">
        <Slider {...settingsRow2}>
          {rows2.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider-row">
        <Slider {...settingsRow3}>
          {rows3.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CustomSliderSm;
