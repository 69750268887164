import React from "react";
import "./App.css";
import Layout from "./components/layout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DesignPage from "./pages/designPage";
import MarketingPage from "./pages/marketingPage";
import ConsultingPage from "./pages/consultingPage";
import ContactPage from "./pages/contactPage";
import RealisationPage from "./pages/realisationPage";
import ServicePage from "./pages/servicePage";
import AboutPage from "./pages/aboutPage";
import DevelopementPage from "./pages/developementPage";
import BlogPage from "./pages/blogPage";
import HomePage from "./pages/homePage";
import BlogPage1 from "./components/blog1";
import LoginPage from "./pages/adminPages/loginPage";

import VerifCode from "./pages/adminPages/verifCodePage";
import AppLayoutAdmin from "./pages/adminPages/appLayoutAdmin";
import Dashbord from "./pages/adminPages/dashbordAdmin";
import MissionListe from "./pages/adminPages/listeMissionPage";
import JobForm from "./pages/adminPages/addMissionPage";
import ContactListe from "./pages/adminPages/contactList";
import NewslettreListe from "./pages/adminPages/NewsLetter";
import CandidatureListe from "./pages/adminPages/candidatureListe";
import { ToastContainer } from "react-toastify";
import RealisationListePages from "./pages/adminPages/realisationListe/realisationListe";
import RealisationForm from "./pages/adminPages/addRealisation";
import ProtectedRoute from "./pages/adminPages/protectedRoute";
import UserListePages from "./pages/adminPages/userListe";
import RejoinezPage from "./pages/rejoinezPage";
import DetailsPage from "./pages/offerDetails";
import MentionsLegalsPage from "./pages/mentionsLegals";

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/design"
          element={
            <Layout>
              <DesignPage />
            </Layout>
          }
        />
        <Route
          path="/marketing"
          element={
            <Layout>
              <MarketingPage />
            </Layout>
          }
        />
        <Route
          path="/blog/:id"
          element={
            <Layout>
              <BlogPage1 />
            </Layout>
          }
        />
        <Route
          path="/consulting"
          element={
            <Layout>
              <ConsultingPage />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <ContactPage />
            </Layout>
          }
        />
        <Route
          path="/mentionsLégales"
          element={
            <Layout>
              <MentionsLegalsPage />
            </Layout>
          }
        />
        <Route
          path="/rejoignez-nous"
          element={
            <Layout>
              <RejoinezPage />
            </Layout>
          }
        />
        <Route
          path="/realisation"
          element={
            <Layout>
              <RealisationPage />
            </Layout>
          }
        />
        <Route
          path="/service"
          element={
            <Layout>
              <ServicePage />
            </Layout>
          }
        />
        <Route
          path="/about"
          element={
            <Layout>
              <AboutPage />
            </Layout>
          }
        />
        <Route
          path="/developpemnt"
          element={
            <Layout>
              <DevelopementPage />
            </Layout>
          }
        />
        <Route
          path="/blog"
          element={
            <Layout>
              <BlogPage />
            </Layout>
          }
        />
        <Route
          path="/offerDetails/:id"
          element={
            <Layout>
              <DetailsPage />
            </Layout>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/verif-code" element={<VerifCode />} />
        <Route
          path="/dashbord"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <Dashbord />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mission-liste"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <MissionListe />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
        <Route
          path="/realisation-liste"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <RealisationListePages />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />

        <Route
          path="/candidature-liste"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <CandidatureListe />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact-liste"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <ContactListe />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-liste"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <UserListePages />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
        <Route
          path="/newsletter-liste"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <NewslettreListe />
              </AppLayoutAdmin>{" "}
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-mission"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <JobForm />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-mission/:id"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <JobForm />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-realisation"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <RealisationForm />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-realisation/:id"
          element={
            <ProtectedRoute>
              <AppLayoutAdmin>
                <RealisationForm />
              </AppLayoutAdmin>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
