import React, { useEffect, useState } from "react";
import "./index.scss";

import RealisationService from "../../services/realisation/realisation.service";
import { showErrorToaster } from "../../components/toaster/error";
import { useParams } from "react-router-dom";
const realisationService = new RealisationService();
const BlogPage1 = () => {
  const [blogPost, setRealisation] = useState();
  const { id } = useParams();
  const loadData = async () => {
    try {
      const res = await realisationService?.getRealisationBySlug(id);
      setRealisation(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }
  };
  useEffect(() => {
    loadData();
  }, [id]);

  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  function getImageURL(candidatureId) {
    return `${apiUrl}/realisation/${candidatureId}/image`;
  }
  return (
    <>
      <div className="container">
        <div className="row mt-5   align-items-center ">
          <div className="col-12 mt-5 ">
            <img
              src={getImageURL(blogPost?.id)} // Remplacez par votre image
              className=" img-fluid w-100 height-img-blog"
            />
          </div>
          <div className="col-9 mt-5 mx-auto">
            <h2 className="blog1-top-title">{blogPost?.title}</h2>
            <p className="">
              <div
                dangerouslySetInnerHTML={{
                  __html: blogPost?.description,
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogPage1;
