import axios from "../../config/axiosConfig";

export default class CandidatureService {
  addCandidature = async (data) => {
    try {
      const response = axios.post("/candidatures", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la candidatures : " + error.message
      );
    }
  };
  getAllCandidature = async () => {
    try {
      const response = axios.get("/candidatures");
      return response;
    } catch (error) {
      throw new Error("Erreur  : " + error.message);
    }
  };
}
