import React from "react";
import mentions from "../../assets/Mentions Légales.png";
import "./index.scss";
const MentionsLegalsPage = () => {
  return (
    <section className="design-section container-design">
      <div className="design-title text-center mb-5">
        {" "}
        <img
          src={mentions}
          alt="Image à droite"
          className="design-img img-fluid"
        />
      </div>
      <p className="mention-description  mb-5 ">
        Éditeur du site
        <br /> Nom de l’entreprise : MSIT Conseil <br />
        Forme juridique : [Indiquez la forme juridique, ex. SAS, SARL, etc.]{" "}
        <br />
        Capital social : [Indiquez le montant du capital] <br /> Adresse :
        [Adresse complète de l’entreprise] <br /> Numéro SIRET : [Numéro SIRET
        de l’entreprise] <br />
        RCS : [Ville du registre du commerce et des sociétés, ex. RCS Paris]{" "}
        <br />
        Téléphone : [Numéro de téléphone]
        <br /> Email : [Adresse email de contact] <br /> Directeur de la
        publication : [Nom du responsable, ex. le gérant ou le président] <br />
        Hébergeur du site <br /> Nom de l’hébergeur : [Indiquez le nom, ex. OVH,
        IONOS, etc.] <br /> Adresse : [Adresse complète de l’hébergeur] <br />
        Téléphone : [Numéro de téléphone de l’hébergeur] <br />
        Site Web : [Lien vers le site de l’hébergeur] <br />
        Propriété intellectuelle <br />
        L’ensemble des contenus présents sur le site https://msit-conseil.fr/
        (textes, images, vidéos, graphismes, logos, etc.) est protégé par le
        droit d’auteur et demeure la propriété exclusive de MSIT Conseil, sauf
        mention contraire. Toute reproduction, distribution, modification ou
        utilisation sans autorisation préalable écrite est strictement
        interdite. <br /> Données personnelles <br /> Conformément au Règlement
        Général sur la Protection des Données (RGPD) et à la loi Informatique et
        Libertés, MSIT Conseil s’engage à protéger les données personnelles des
        utilisateurs du site.
        <br /> Responsable du traitement : [Nom de l’entreprise] <br /> Les
        données collectées via le formulaire de contact ou d’inscription sont
        utilisées uniquement pour répondre aux demandes des utilisateurs ou à
        des fins commerciales légitimes, sauf opposition de votre part.
        <br /> Vous disposez d’un droit d’accès, de rectification, de
        suppression et de limitation du traitement de vos données. Pour exercer
        ces droits, veuillez nous contacter à l’adresse suivante : [adresse
        email de contact]. <br /> Pour plus d’informations, consultez notre
        Politique de Confidentialité. <br /> Cookies <br />
        Le site https://msit-conseil.fr/ utilise des cookies pour améliorer
        l’expérience utilisateur et à des fins statistiques. Vous pouvez
        paramétrer vos préférences en matière de cookies via votre navigateur ou
        en consultant notre Politique de Cookies. <br /> Responsabilité <br />{" "}
        MSIT Conseil met tout en œuvre pour garantir l’exactitude des
        informations présentes sur son site. Cependant, l’entreprise ne saurait
        être tenue responsable des erreurs, omissions ou des éventuels dommages
        résultant de l’utilisation des informations contenues sur le site.
        <br /> Contact <br /> Pour toute question ou réclamation, vous pouvez
        nous joindre : <br />
        Par email : [Adresse email] <br />
        Par courrier : [Adresse postale]
        <br /> Par téléphone : [Numéro de téléphone]
      </p>
    </section>
  );
};

export default MentionsLegalsPage;
