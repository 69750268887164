import React, { useState } from "react";
import "./index.scss";

import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/logof.svg";
import { showErrorToaster } from "../../../components/toaster/error";
import { showSuccessToaster } from "../../../components/toaster/success";
import UserService from "../../../services/user-auth/user.service";
const userService = new UserService();
const VerifCode = () => {
  const [code, setCode] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {};
  const [error, setError] = useState("");

  const handleCode = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    setError(""); // Réinitialise l'erreur

    // Validation simple des champs
    if (!code) {
      setError("Veuillez remplir tous les champs.");
      return;
    } else {
      try {
        // Préparer les données à envoyer
        const data = {
          email: email,
          verificationCode: code,
        };

        // Appeler la méthode du service pour ajouter la formation
        const rep = await userService.VerifCodeUser(data);

        // Afficher le toast de succès
        showSuccessToaster("Connexion réussie");
        localStorage.setItem(
          "userLoginRequestID",
          rep?.data?.token?.payload?.userId
        );
        // Réinitialiser le champ et l'erreur
        navigate("/dashbord");
        setCode("");
      } catch (error) {
        // Afficher le toast d'erreur
        showErrorToaster(
          error.response
            ? error.response.data.message
            : "Une erreur est survenue, veuillez réessayer."
        );
      }
    }
  };
  return (
    <div className="loginl">
      <div className="login-container">
        <div className="login-box">
          <div className="login-header">
            <img src={logo} />
          </div>
          <br />
          <form className="login-form" onSubmit={handleCode}>
            <h2 className="text-white">Code de vérification</h2>
            <p className="text-white">
              Vérifiez votre email pour le code de vérification
            </p>

            {/* Message d'erreur */}
            {error && <div className="error-message">{error}</div>}

            <div className="form-group">
              <label>code de vérification</label>
              <input
                type="code"
                className="form-control"
                placeholder="Enter your code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>

            <button type="submit" className="w-100 btn btn-primary btn-block">
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifCode;
