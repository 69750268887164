import React from "react";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import slide_image_1 from "../../assets/desi1.png";
import slide_image_2 from "../../assets/desi2.png";
import slide_image_3 from "../../assets/desi3.png";
import slide_image_4 from "../../assets/desi4.png";
import slide_image_5 from "../../assets/desi5.png";
import design from "../../assets/design.png";
import design1 from "../../assets/design1.png";
import design2 from "../../assets/design2.png";
import design3 from "../../assets/design3.png";
import design4 from "../../assets/design4.png";
import desm1 from "../../assets/desm1.png";
import desm2 from "../../assets/desm2.png";
import desm4 from "../../assets/desm4.png";
import "./index.scss";

const DesignPage = () => {
  return (
    <section className="design-section container-design">
      <div className="design-title text-center mb-5">
        {" "}
        <img
          src={design}
          alt="Image à droite"
          className="design-img img-fluid"
        />
      </div>
      <p className="design-description  mb-5 ">
        Dans un marché où l’image est primordiale, une identité visuelle bien
        pensée est essentielle pour captiver votre audience et vous démarquer.
        Notre équipe de designers experts vous accompagne dans la création d’une
        image forte et cohérente à travers des services de design graphique sur
        mesure, alliant créativité et stratégie.
      </p>

      <section className="section-des des-web  ">
        <div className="row mb-5">
          <div className="col-md-6 ">
            <h2 className="titre-des width-85">Logos et charte graphiques</h2>
            <p className="descri-des width-85">
              Nous sommes amenés très régulièrement à accompagner nos clients
              dans la construction de leur identité graphique. Le logotype est
              un élément primordial, auquel nous portons une attention toute
              particulière. Il doit être efficace dans son message et assez
              riche pour être fondateur de votre charte graphique. Ceci depuis
              les premières ébauches jusqu'au dessin final vectoriel, en passant
              par les déclinaisons de formats et le calage des couleurs.
            </p>
          </div>
          <div className="col-md-6 text-center">
            <img src={design1} alt="Image à droite" className="img-fluid  " />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <img
              src={design2}
              alt="Image à gauche"
              className="img-fluid margin-100"
            />
          </div>
          <div className="col-md-6">
            <h2 className="titre-des  width-95-12">
              Supports de communication
            </h2>
            <p className="descri-des width-85-22">
              Nous concevons tous les types de supports de communication :
              cartes de visites, papier à entête, brochures, dépliants,
              catalogues, etc. Nous avons également une grande expertise dans le
              domaine des documents grand format : habillages de murs
              d’exposition, stands pour salons, posters.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <h2 className="titre-des width-75-10">UX/UI Design</h2>
            <p className="descri-des width-85">
              Chez Msit Conseil, nos équipes créatives veillent activement pour
              mettre en lumière les dernières tendances graphiques sur vos
              plateformes digitales. Nous pensons qu’il est essentiel de mettre
              l’utilisateur au centre de toutes nos conceptions. L’UX et l’UI
              design nous permettent d’aboutir une expérience et une navigation
              optimale. Nous adoptons une approche UX/UI centrée sur l’humain et
              orientée business.
            </p>
          </div>
          <div className="col-md-6">
            <img src={design3} alt="Image à droite" className="img-fluid" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={design4} alt="Image à gauche" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="titre-des margin-15">Photographie</h2>
            <p className="descri-des width-85-22">
              Nous réalisons régulièrement des prises de vue pour illustrer les
              sites de nos clients et créer des éléments visuels pour leur
              stratégie marketing et leurs catalogues sur les réseaux sociaux.
              Nous réalisons des Packs shot (shooting produit) pour promouvoir
              vos services, augmenter la valeur de votre marque et générer de
              nouveaux clients.
            </p>
          </div>
        </div>
      </section>
      <section className="section-des des-mobile  ">
        <div className="row">
          <div className="col-7">
            <h2 className="titre-des ">Logos et charte graphiques</h2>
            <p className="descri-des ">
              Nous sommes amenés très régulièrement à accompagner nos clients
              dans la construction de leur identité graphique.
            </p>
          </div>
          <div className="col-5">
            {" "}
            <img src={desm1} alt="Image à gauche" className="img-fluid h-100" />
          </div>
        </div>
        <p className="descri-des ">
          Le logotype est un élément primordial, auquel nous portons une
          attention toute particulière. Il doit être efficace dans son message
          et assez riche pour être fondateur de votre charte graphique. Ceci
          depuis les premières ébauches jusqu'au dessin final vectoriel, en
          passant par les déclinaisons de formats et le calage des couleurs.
        </p>
        <h2 className="titre-des1 ">Supports de communication</h2>
        <img src={desm2} alt="Image à gauche" className="img-fluid " />
        <p className="descri-des ">
          Nous concevons tous les types de supports de communication : cartes de
          visites, papier à entête, brochures, dépliants, catalogues, etc. Nous
          avons également une grande expertise dans le domaine des documents
          grand format : habillages de murs d’exposition, stands pour salons,
          posters.
        </p>
        <div className="row">
          <div className="col-4">
            <h2 className="titre-des ">UX/UI Design</h2>
          </div>
          <div className="col-8">
            {" "}
            <img src={design3} alt="Image à gauche" className="img-fluid " />
          </div>
        </div>
        <p className="descri-des ">
          Chez Msit Conseil, nos équipes créatives veillent activement pour
          mettre en lumière les dernières tendances graphiques sur vos
          plateformes digitales. Nous pensons qu’il est essentiel de mettre
          l’utilisateur au centre de toutes nos conceptions. L’UX et l’UI design
          nous permettent d’aboutir une expérience et une navigation optimale.
          Nous adoptons une approche UX/UI centrée sur l’humain et orientée
          business.
        </p>
        <h2 className="titre-des2 ">Photographie</h2>
        <img src={desm4} alt="Image à gauche " className="img-fluid marg" />
        <p className="descri-des ">
          Nous réalisons régulièrement des prises de vue pour illustrer les
          sites de nos clients et créer des éléments visuels pour leur stratégie
          marketing et leurs catalogues sur les réseaux sociaux. Nous réalisons
          des Packs shot (shooting produit) pour promouvoir vos services,
          augmenter la valeur de votre marque et générer de nouveaux clients.
        </p>
      </section>
      <h3 className="references-title mb-5 text-center">Nos Références</h3>
      <div className="swiper-design">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container "
        >
          <SwiperSlide>
            <a href="https://www.instagram.com/skinpeccable.skincare/">
              {" "}
              <img src={slide_image_1} alt="slide_image" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.instagram.com/maitrise.toit/">
              <img src={slide_image_2} alt="slide_image" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.instagram.com/11.management.sports/">
              <img src={slide_image_3} alt="slide_image" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.instagram.com/skinpeccable.skincare/">
              <img src={slide_image_4} alt="slide_image" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.instagram.com/11.management.sports/">
              <img src={slide_image_5} alt="slide_image" />
            </a>
          </SwiperSlide>

          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <GrFormPrevious color="#002B58" />
            </div>
            <div className="swiper-button-next slider-arrow">
              <MdNavigateNext color="#002B58" />
            </div>
          </div>
        </Swiper>
      </div>
    </section>
  );
};

export default DesignPage;
