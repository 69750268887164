import React from "react";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import crm from "../../assets/crm.svg";
import dev1 from "../../assets/dev1.svg";
import slide_image_1 from "../../assets/dev11.png";
import dev1m from "../../assets/dev1m.svg";
import dev2 from "../../assets/dev2.svg";
import slide_image_2 from "../../assets/dev22.png";
import slide_image_3 from "../../assets/dev33.png";
import slide_image_4 from "../../assets/dev44.png";
import slide_image_5 from "../../assets/dev55.png";
import dev from "../../assets/devv.gif";
import developement from "../../assets/développement.svg";
import seo from "../../assets/SEO.svg";
import "./index.scss";
import { Helmet } from "react-helmet-async";
const DevelopementPage = () => {
  return (
    <>
      <Helmet>
        <title>Développement - MSIT Conseil</title>
        <meta
          name="description"
          content="MSIT Conseil, votre partenaire digital
pour la création, le développement et
l’optimisation d’applications, CRM et
stratégies SEO adaptées à vos besoins."
        />
      </Helmet>
      <div className="developement-section container-developement container mb-5 ">
        <div className="developement-title text-center mb-5">
          <img
            src={developement}
            alt="Image à droite"
            className="developement-img img-fluid"
          />
        </div>

        <p className="developement-description   mb-5">
          Bienvenue chez MSIT Conseil, votre partenaire de confiance pour des
          solutions digitales sur mesure. Nous vous accompagnons dans la
          création, le développement et l’optimisation de vos projets grâce à
          des applications web et mobiles performantes, des solutions CRM
          personnalisées et des stratégies SEO efficaces, entièrement adaptées à
          vos besoins.
        </p>
      </div>
      <div className="section w-100 p-0  dev-web">
        <div className="row gx-0 mb-5 p-0">
          <div className="col-md-7">
            <img src={dev2} alt="Image à gauche" className="img-fluid" />
          </div>

          <div className="col-md-5 p-0" style={{ marginLeft: "-12%" }}>
            <h2 className="titre-mark">Développement Web </h2>

            <p
              className="descri-mark "
              style={{ width: "65%", marginLeft: "34%" }}
            >
              Nous proposons des solutions de développement web sur mesure,
              alliant expertise technique et design innovant, pour créer des
              sites performants qui répondent précisément aux besoins de votre
              entreprise.
            </p>
          </div>
        </div>

        <div className="row gx-0 p-0 m-0 ">
          <div className="col-md-5 p-0  ">
            <h2 className="titre-mark">Application Mobile</h2>
            <p
              className="descri-mark"
              style={{ marginLeft: "24%", width: "75%" }}
            >
              Nous développons des applications mobiles sur-mesure, intuitives
              et performantes, conçues pour offrir une expérience utilisateur
              fluide et répondre aux besoins spécifiques de votre entreprise.
            </p>
          </div>
          <div className="col-md-7 p-0 left-top ">
            <img
              src={dev}
              alt="Image à gauche"
              className="img-fluid"
              style={{ display: "block", marginLeft: "auto" }}
            />
          </div>
        </div>
        <div
          className="row gx-0  align-items-center mb-5 p-0 "
          style={{ marginTop: "-8%" }}
        >
          <div className="col-md-7">
            <img src={crm} alt="Image à gauche" />
          </div>
          <div className="col-md-5" style={{ marginLeft: "-12%" }}>
            <h2 className="titre-mark" style={{ marginLeft: "-280px" }}>
              crm
            </h2>
            <p
              className="descri-mark"
              style={{ width: "65%", marginLeft: "34%" }}
            >
              Nous proposons des solutions CRM personnalisées, permettant à
              votre entreprise de gérer efficacement ses relations clients,
              d'optimiser les interactions et d'améliorer la fidélisation grâce
              à des outils puissants et intuitifs.
            </p>
          </div>
        </div>
        <div className="row gx-0 p-0 m-0 ">
          <div className="col-md-5 p-0  ">
            <h2 className="titre-mark">seo</h2>
            <p
              className="descri-mark"
              style={{ marginLeft: "24%", width: "75%" }}
            >
              Nous offrons des services SEO stratégiques et personnalisés pour
              améliorer la visibilité de votre site web, augmenter votre trafic
              organique et maximiser vos performances sur les moteurs de
              recherche.
            </p>
          </div>
          <div className="col-md-7 p-0">
            <img
              src={seo}
              alt="Image à gauche"
              className="img-fluid mobile-sizseo"
              style={{ display: "block", marginLeft: "9%" }}
            />
          </div>
        </div>
      </div>
      <section className="section dev-mobile  ">
        <h2 className="titre-mark width-mob-dev">Développement Web </h2>
        <img src={dev1m} alt="Image à gauche" className="img-fluid w-100" />
        <p className="descri-mark ">
          Nous proposons des solutions de développement web sur-mesure, alliant
          expertise technique et design innovant, pour créer des sites
          performants qui répondent précisément aux besoins de votre entreprise.
        </p>
        <h2 className="titre-mark width-mob-dev">Application Mobile</h2>
        <img
          src={dev}
          alt="Image à gauche"
          className="img-fluid"
          style={{ display: "block", marginLeft: "auto" }}
        />
        <p className="descri-mark">
          Nous développons des applications mobiles sur-mesure, intuitives et
          performantes, conçues pour offrir une expérience utilisateur fluide et
          répondre aux besoins spécifiques de votre entreprise.
        </p>
        <h2 className="titre-mark">crm</h2>
        <img src={crm} alt="Image à gauche" className="img-fluid" />
        <p className="descri-mark">
          Nous proposons des solutions CRM personnalisées, permettant à votre
          entreprise de gérer efficacement ses relations clients, d'optimiser
          les interactions et d'améliorer la fidélisation grâce à des outils
          puissants et intuitifs.
        </p>
        <h2 className="titre-mark">seo</h2>
        <img
          src={seo}
          alt="Image à gauche"
          className="img-fluid"
          style={{ display: "block", marginLeft: "auto" }}
        />
        <p className="descri-mark">
          Nous offrons des services SEO stratégiques et personnalisés pour
          améliorer la visibilité de votre site web, augmenter votre trafic
          organique et maximiser vos performances sur les moteurs de recherche.
        </p>
      </section>
      <section className="design-section container-design">
        <h3 className="references-title text-center">Nos Références</h3>
        <div className="swiper-design">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container "
          >
            <SwiperSlide>
              <a href="https://juniormbuyifoundation.com/">
                {" "}
                <img src={slide_image_1} alt="slide_image" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://www.jpgconsulting-partners.com/">
                <img src={slide_image_2} alt="slide_image" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://11management-sports.com/">
                <img src={slide_image_3} alt="slide_image" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://www.jpgconsulting-partners.com/">
                <img src={slide_image_4} alt="slide_image" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://admin.11management-sports.com/">
                <img src={slide_image_5} alt="slide_image" />
              </a>
            </SwiperSlide>

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <GrFormPrevious color="#002B58" />
              </div>
              <div className="swiper-button-next slider-arrow">
                <MdNavigateNext color="#002B58" />
              </div>
            </div>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default DevelopementPage;
