import React, { useState } from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import logo from "../../assets/logof.svg";
import UserService from "../../services/user-auth/user.service";
import { showErrorToaster } from "../toaster/error";
import { showSuccessToaster } from "../toaster/success";
const userService = new UserService();
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    setError(""); // Réinitialise l'erreur

    // Validation simple des champs
    if (!email || !password) {
      setError("Veuillez remplir tous les champs.");
      return;
    } else {
      try {
        // Préparer les données à envoyer
        const data = {
          email: email,
          password: password,
        };

        // Appeler la méthode du service pour ajouter la formation
        const rep = await userService.loginUser(data);

        // Afficher le toast de succès
        showSuccessToaster(
          "Connexion réussie, vérifiez votre email pour le code de vérification"
        );

        // Réinitialiser le champ et l'erreur
        setEmail("");
        setPassword("");
        navigate("/verif-code", { state: { email } });
      } catch (error) {
        // Afficher le toast d'erreur
        showErrorToaster(
          error.response
            ? error.response.data.message
            : "Une erreur est survenue, veuillez réessayer."
        );
      }
    }

    // Simuler une authentification

    /*  if (email === fakeUser.email && password === fakeUser.password) {
      setSuccess(true); // Connexion réussie
    } else {
      setError("Email ou mot de passe incorrect.");
    } */
  };
  return (
    <div className="loginl">
      <div className="login-container">
        <div className="login-box">
          <div className="login-header">
            <img src={logo} />
          </div>
          <form className="login-form text-white" onSubmit={handleLogin}>
            <h2>Login</h2>

            {/* Message d'erreur */}
            {error && <div className="error-message">{error}</div>}

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="form-footer text-white">Forgot Password?</div>
            <button type="submit" className="w-100 btn btn-primary btn-block">
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
