import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import s11 from "../../assets/brr1.png";
import s12 from "../../assets/brr2.png";
import s13 from "../../assets/brr3.png";
import s14 from "../../assets/brr4.png";
import s21 from "../../assets/brr5.png";
import s22 from "../../assets/brr6.png";
import s111 from "../../assets/br111.png";
import s122 from "../../assets/br12.png";
import s133 from "../../assets/br13.png";
import s144 from "../../assets/br14.png";
import s155 from "../../assets/br15.png";
import s166 from "../../assets/br16.png";
import s177 from "../../assets/br21.png";
import s188 from "../../assets/br22.png";
import s199 from "../../assets/br23.png";
import s110 from "../../assets/br24.png";
import s1110 from "../../assets/br25.png";
import "./index.scss";
const CustomSlider = () => {
  // Paramètres pour chaque slider
  const settingsRow1 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false, // Direction de droite à gauche
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsRow2 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: -1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: -1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: -1,
        },
      },
    ],
  };

  const settingsRow3 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
    // Direction de droite à gauche
  };
  const rows1 = [
    { id: 1, image: s11, alt: " brand 1" },
    { id: 2, image: s12, alt: " brand 2" },
    { id: 3, image: s13, alt: "brand 3" },
    { id: 4, image: s14, alt: "brand 4" },
    { id: 5, image: s21, alt: "brand 3" },
    { id: 6, image: s22, alt: "brand 4" },
  ];
  const rows2 = [
    { id: 1, image: s111, alt: " brand 1" },
    { id: 2, image: s122, alt: " brand 2" },
    { id: 3, image: s133, alt: "brand 3" },
    { id: 4, image: s144, alt: "brand 4" },
    { id: 5, image: s155, alt: "brand 3" },
    { id: 6, image: s166, alt: "brand 4" },
  ];
  const rows3 = [
    { id: 1, image: s177, alt: " brand 1" },
    { id: 2, image: s188, alt: " brand 2" },
    { id: 3, image: s199, alt: "brand 3" },
    { id: 4, image: s110, alt: "brand 4" },
    { id: 5, image: s1110, alt: "brand 3" },
  ];

  return (
    <div className="margintop">
      <div className="slider-row">
        <Slider {...settingsRow1}>
          {rows1.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider-row">
        <Slider {...settingsRow2}>
          {rows2.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider-row">
        <Slider {...settingsRow3}>
          {rows3.map((row) => (
            <div key={row.id} className="row-slider">
              <img src={row.image} alt={row.alt} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CustomSlider;
