import axios from "../../config/axiosConfig";

export default class ContactService {
  addContact = async (data) => {
    try {
      const response = axios.post("/contact", data);
      return response.data;
    } catch (error) {
      throw new Error(
        "Erreur lors de la création de la contact : " + error.message
      );
    }
  };
  getAllContact = async () => {
    try {
      const response = axios.get("/contact");
      return response;
    } catch (error) {
      throw new Error("Erreur  : " + error.message);
    }
  };
}
