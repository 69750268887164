import React from "react";
import mark1 from "../../assets/mark1.png";
import mark1m from "../../assets/mark1m.svg";
import mark2 from "../../assets/mark2.png";
import mark3 from "../../assets/mark3.png";
import marketing from "../../assets/marketing.png";
import markm from "../../assets/marm.svg";
import resultat from "../../assets/Result.png";
import "./index.scss";
import { Helmet } from "react-helmet-async";
const MarketingPage = () => {
  return (
    <>
      <Helmet>
        <title>Marketing - MSIT Conseil</title>
        <meta
          name="description"
          content="MSIT Conseil vous accompagne avec
des solutions de marketing digital pour
booster votre visibilité, attirer des
prospects qualifiés et maximiser vos
conversions."
        />
      </Helmet>
      <div className="marketing-section container    mb-md-5 mb-0">
        <div className="marketing-title text-center mb-md-5 mb-3">
          <img
            src={marketing}
            alt="Image à droite"
            className="marketing-img img-fluid"
          />
        </div>
        <p className="marketing-description width-desc1  mb-md-5 mb-3">
          Nous vous accompagnons dans la mise en place de solutions efficaces et
          personnalisées pour augmenter votre visibilité, attirer des prospects
          qualifiés et maximiser vos conversions. Découvrez nos services de
          marketing digital qui allient expertise, créativité et technologie
          pour propulser votre entreprise vers de nouveaux sommets.
        </p>
        <div className="text-center ">
          {" "}
          <img src={resultat} alt="Image à droite" className="img-fluid" />
        </div>

        <p className="marketing-description width-desc   mb-5">
          Nous vous fournirons une variété de solutions de marketing numérique
          efficaces qui aideront votre entreprise à prospérer en ligne, à se
          démarquer de la concurrence et atteindre une croissance soutenue.
        </p>
      </div>
      <div className="section-1 w-100 p-0  mark-web">
        <div className="row mb-5 p-0">
          <div className="col-md-7">
            <img src={mark2} alt="Image à gauche" className="img-fluid" />
          </div>

          <div className="col-md-5 p-0  margin-7">
            <h2 className="titre-mark width-90-tit">
              Emailing et génération des leads{" "}
            </h2>

            <p
              className="descri-mark"
              style={{ width: "75%", marginLeft: "24%" }}
            >
              Pour attirer vos prospects et les convertir en clients, on vous
              aidera à capter leur attention en leur présentant des contenus
              adaptés à leurs besoins et en les accompagnant en couceur jusqu'à
              l'acte d'achat.
            </p>
          </div>
        </div>

        <div className="row mb-5 p-0 m-0 ">
          <div className="col-md-5 p-0  ">
            <h2 className="titre-mark">Réseaux sociaux </h2>
            <p
              className="descri-mark"
              style={{ marginLeft: "24%", width: "75%" }}
            >
              Nous intervenons tout au long des étapes du processus social média
              : de la mise au point stratégique, jusqu'au déploiement, en
              passant par la gestion clé en main des comptes.
            </p>
          </div>
          <div className="col-md-7 p-0">
            <img
              src={mark1}
              alt="Image à gauche"
              className="img-fluid"
              style={{ display: "block", marginLeft: "auto" }}
            />
          </div>
        </div>
        <div className="row mb-5 p-0 ">
          <div className="col-md-7">
            <img src={mark3} alt="Image à gauche" className="img-fluid" />
          </div>
          <div
            className="col-md-5"
            style={{ marginLeft: "-7%", marginTop: "180px" }}
          >
            <h2 className="titre-mark" style={{ marginLeft: "-280px" }}>
              Référencement Naturel
            </h2>
            <p
              className="descri-mark"
              style={{ marginLeft: "15%", width: "85%" }}
            >
              Nous accompagnons les entreprises de toute taille dans la mise en
              œuvre de leur stratégie de visibilité sur Internet. Nous proposons
              un accompagnement personnalisé et adapté à l'organisation et aux
              contraintes de nos clients, afin d'adapter des solutions en accord
              avec leurs objectifs.
            </p>
          </div>
        </div>
      </div>{" "}
      <div className="section-1 w-100 p-0  mark-mobile">
        <h2 className="titre-mark p-4">Emailing et génération des leads </h2>
        <img src={mark2} alt="Image à gauche" className="img-fluid" />
        <p className="descri-mark p-4">
          Pour attirer vos prospects et les convertir en clients, on vous aidera
          à capter leur attention en leur présentant des contenus adaptés à
          leurs besoins et en les accompagnant en couceur jusqu'à l'acte
          d'achat.
        </p>
        <h2 className="titre-mark  p-4">Réseaux sociaux </h2>
        <img src={markm} alt="Image à gauche" className="img-fluid" />
        <p className="descri-mark p-4">
          Nous intervenons tout au long des étapes du processus social média :
          de la mise au point stratégique, jusqu'au déploiement, en passant par
          la gestion clé en main des comptes.
        </p>
        <h2 className="titre-mark  p-4">Référencement Naturel</h2>
        <img src={mark1m} alt="Image à gauche" className="img-fluid" />
        <p className="descri-mark p-4">
          Nous accompagnons les entreprises de toute taille dans la mise en
          œuvre de leur stratégie de visibilité sur Internet. Nous proposons un
          accompagnement personnalisé et adapté à l'organisation et aux
          contraintes de nos clients, afin d'adapter des solutions en accord
          avec leurs objectifs.
        </p>
      </div>
    </>
  );
};

export default MarketingPage;
