import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import serr1 from "../../assets/serh1.png";
import serr4 from "../../assets/serh4.png";
import serr3 from "../../assets/serh3.png";
import serr2 from "../../assets/serh2.png";
import text1 from "../../assets/text1.svg";
import text2 from "../../assets/text2.svg";
import text3 from "../../assets/text3.svg";
import text4 from "../../assets/text4.svg";
import "./index.scss";
const ServiceHome = () => {
  const [rotation, setRotation] = useState(0);

  const handleClick = () => {
    // Ajoute 90 degrés (25% du cercle) à la rotation actuelle
    setRotation((prevRotation) => prevRotation + 80);
  };
  const rows1 = [
    {
      id: 1,
      image: serr1,
      alt: " brand 1",
      rotation1: 30,
      rotation2: 30,
      text: text1,
    },
    {
      id: 2,
      image: serr2,
      alt: " brand 2",
      rotation1: 250,
      rotation2: 80,
      text: text2,
    },
    {
      id: 3,
      image: serr3,
      alt: "brand 3",
      rotation1: 287,
      rotation2: 77,
      text: text3,
    },
    {
      id: 4,
      image: serr4,
      alt: "brand 4",
      rotation1: 280,
      rotation2: 100,
      text: text4,
    },
  ];
  const swiperRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideClick = (direction) => {
    if (swiperRef.current) {
      if (direction === "next") {
        swiperRef.current.swiper.slideNext();
      } else {
        swiperRef.current.swiper.slidePrev();
      }
    }
  };
  function handleClick1(event, ang, ang1, id) {
    // Access the classes of the clicked div
    const divClasses = event.target.closest(".swiper-slide").classList;

    if (divClasses.contains("swiper-slide-prev")) {
      handleSlideClick("prev"); // Navigate to the previous slide
      if (id == 1) {
        setRotation(360);
      } else {
        setRotation((prevRotation) => prevRotation + ang1);
      }
    } else if (divClasses.contains("swiper-slide-next")) {
      handleSlideClick("next"); // Navigate to the next slide
      if (id == 1) {
        setRotation(360);
      } else {
        setRotation((prevRotation) => prevRotation + ang);
      }
    }
  }
  const handlePrevClick = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      const prevIndex =
        swiper.realIndex === 0 ? rows1.length - 1 : swiper.realIndex - 1; // Détermine l'index de la diapositive précédente
      const { id, rotation2: ang } = rows1[prevIndex]; // Récupère l'ID et l'ang de la diapositive précédente

      // Applique la logique de rotation
      if (id === 1) {
        setRotation(360);
      } else {
        setRotation((prevRotation) => prevRotation + ang);
      }

      swiper.slidePrev(); // Fait défiler vers la diapositive précédente
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      const nextIndex = (swiper.realIndex + 1) % rows1.length; // Détermine l'index de la diapositive suivante
      const { id, rotation1: ang } = rows1[nextIndex]; // Récupère l'ID et l'ang de la diapositive suivante

      // Applique la logique de rotation
      if (id === 1) {
        setRotation(360);
      } else {
        setRotation((prevRotation) => prevRotation + ang);
      }
      swiper.slideNext();
    }
  };
  const handleSlideChange = (swiper) => {
    const centerSlideIndex = swiper.realIndex;
    const centeredSlideId = rows1[centerSlideIndex].id;

    setActiveSlideIndex(centerSlideIndex);
  };
  return (
    <div>
      <div className="row gx-0 w-100 justify-content-center align-items-center mt-5">
        <p className="serviceH-title mt-5">nos services</p>{" "}
        <div className="custom-swiper-container">
          <Swiper
            ref={swiperRef}
            className="custom-swiper"
            spaceBetween={50} // Réduit l'espace entre les slides
            slidesPerView="auto"
            centeredSlides={true}
            loop={true}
            autoplay={{ delay: 3000 }}
            simulateTouch={true}
            touchMoveStopPropagation={true}
            onSlideChange={handleSlideChange}
          >
            {rows1.map(
              ({ id, image, alt, rotation1, text, rotation2 }, index) => (
                <SwiperSlide className="custom-swiper-slide" key={id}>
                  <div
                    onClick={(event) =>
                      handleClick1(event, rotation1, rotation2, id)
                    }
                    className="position-relative"
                  >
                    {/* Image avec animation de zoom */}
                    <img
                      src={image}
                      alt={alt}
                      className="custom-swiper-image"
                    />

                    {/* Texte qui apparaît au survol */}
                    <div className="text-overlay-slideH">
                      <img src={text} alt={alt} className="img-fluid " />
                    </div>

                    {index === activeSlideIndex && (
                      <>
                        <div className="text-slider-swiper position-absolute1 dis-cer-web">
                          <motion.svg
                            width="1200"
                            height="1200"
                            viewBox="0 0 1200 1200"
                            initial={{ rotate: 30 }}
                            animate={{ rotate: rotation }}
                            transition={{ duration: 2 }}
                          >
                            <path
                              id="circlePath"
                              d="M 600,600 m -300,0 a 300,300 0 1,1 600,0 a 300,300 0 1,1 -600,0"
                              fill="transparent"
                            />
                            <motion.text
                              fill="#ffffff"
                              fontSize="27.2"
                              initial={{ rotate: 30 }}
                              style={{ fontFamily: "Qrosbi" }}
                            >
                              <textPath
                                href="#circlePath"
                                startOffset="50%"
                                textAnchor="middle"
                              >
                                . Développement . Marketing . Design .
                                Consulting
                              </textPath>
                            </motion.text>
                          </motion.svg>
                        </div>
                        <div className="text-slider-swiper position-absolute2 dis-cer-mob">
                          <motion.svg
                            width="500"
                            height="500"
                            viewBox="0 0 1200 1200" // Vous pouvez ajuster si nécessaire
                            initial={{ rotate: 30 }}
                            animate={{ rotate: rotation }}
                            transition={{ duration: 2 }}
                          >
                            <path
                              id="circlePath"
                              d="M 600,600 m -300,0 a 300,300 0 1,1 600,0 a 300,300 0 1,1 -600,0"
                              fill="transparent"
                            />
                            <motion.text
                              fill="#ffffff"
                              fontSize="26.2" // Ajustez la taille de la police pour s'adapter à la nouvelle taille
                              fontWeight="bold"
                              initial={{ rotate: 30 }}
                              style={{ fontFamily: "Qrosbi" }}
                            >
                              <textPath
                                href="#circlePath"
                                startOffset="50%"
                                className="animated-text"
                                textAnchor="middle"
                              >
                                .Développement . Marketing . Design . Consulting
                              </textPath>
                            </motion.text>
                          </motion.svg>
                        </div>
                      </>
                    )}
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
        <div className="swiper-buttons d-flex justify-content-center mt-3">
          <button className="btn btn-prev me-3" onClick={handlePrevClick}>
            <GrFormPrevious color="#002B58" size={30} />
          </button>
          <button className="btn btn-next ms-3" onClick={handleNextClick}>
            <MdNavigateNext color="#002B58" size={30} />
          </button>
        </div>
      </div>
    </div>
  );
};
export default ServiceHome;
