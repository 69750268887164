import React, { useEffect, useState } from "react";
import { GrFormPrevious } from "react-icons/gr";
import { MdNavigateNext } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import bgr from "../../assets/bgo.png";
import bgs from "../../assets/bgsp.jpg";
import gifs from "../../assets/gits.gif";
import reg from "../../assets/regoinez.svg";
import { showErrorToaster } from "../../components/toaster/error";
import MissionService from "../../services/mission/mission.service";
import "./index.scss";
import { Helmet } from "react-helmet-async";
import Modal from "../../components/modal";
const missionService = new MissionService();

const RejoinezPage = () => {
  const [items, setMission] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleNavigation = (id) => {
    navigate(`/offerDetails/${id}`);
    window.scrollTo(0, 0);
  };

  const loadData = async () => {
    try {
      const res = await missionService?.getAllActiveMission();
      const sortedData = res?.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt); // Tri décroissant par date
      });
      setMission(sortedData);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = items.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  return (
    <>
      <Helmet>
        <title>Rejoignez-nous - MSIT Conseil</title>
        <meta
          name="description"
          content="Lancez votre carrière avec MSIT Conseil
! Rejoignez notre équipe dynamique et
contribuez à des projets innovants.
Découvrez nos postes ouverts."
        />
      </Helmet>
      <section className="realisation-section text-center mb-md-5 mb-3">
        <img
          src={reg}
          alt="Image à droite"
          className="realisation-img img-fluid mobile-width-rej"
        />
        <p className="realisation-description mb-md-5 mb-3">
          <span className="rejoinez-span">
            Lancez votre carrière avec MSIT Conseil !
          </span>
          <br />
          Rejoignez une entreprise en pleine croissance où innovation et
          expertise se rencontrent. Participez à des appels d’offres de grande
          envergure, développez vos compétences au sein d’une équipe dynamique
          et contribuez à des projets ambitieux.
          <br />
          <span className="rejoinez-span1">
            Rejoignez-nous et écrivez votre succès avec MSIT Conseil !
          </span>
        </p>
      </section>

      <div className="container d-flex justify-content-center">
        <div className="row w-100 justify-content-center align-items-stretch h-100 mb-4">
          {currentItems.map((item, index) => (
            <div className="col-12 col-sm-6 col-md-4 mb-3" key={index}>
              <div
                className="cardr cardhover"
                style={{ backgroundImage: `url(${bgr})` }}
              >
                <div className="card-contentr p-4 text-white">
                  <h2 className="card-title">{item.title}</h2>
                  <p className="card-date">{item?.createdAt?.split("T")[0]}</p>
                  <button
                    type="submit"
                    className="btn submit-button-details"
                    onClick={() => handleNavigation(item.id)}
                  >
                    Voir l'offre
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className="swiper-buttons1 d-flex justify-content-center mt-3">
            <button
              className="btn btn-prev me-3"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <GrFormPrevious color="#002B58" size={30} />
            </button>
            <button
              className="btn btn-next ms-3"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <MdNavigateNext color="#002B58" size={30} />
            </button>
          </div>
        </div>
      </div>
      <Modal showModal={showModal} handleClose={closeModal} missionId={null} />
      <div className="container-spontane">
        <h2 className="titre-spontane">Candidature spontanée</h2>
        <div
          className="row mb-4 card-sp-img cardr"
          style={{ backgroundImage: `url(${bgs})` }}
        >
          <div className="col-7">
            <p className="p-spon">
              N'hésitez pas à nous envoyer votre CV. Nous sommes toujours à la
              recherche de personnes ambitieuses et talentueuses pour rejoindre
              notre équipe.
            </p>
            <button
              type="submit"
              className="btn submit-button-sp"
              onClick={openModal}
            >
              Postulez
            </button>
          </div>
          <div className="col-3"></div>
        </div>
        <img src={gifs} className="gif-sp img-fluid" />
      </div>

      <div className="container justify-content-center position-relative mo-dis">
        <h2 className="titre-spontane">Candidature spontanée</h2>
      </div>

      <div className="container d-flex justify-content-center heigth-sp position-relative mo-dis">
        <div className="row w-100 justify-content-center h-100 align-items-stretch h-100 mb-4 no-margin gx-0">
          <div className="col-12 col-sm-6 col-md-4 mb-3">
            <div className="cardr" style={{ backgroundImage: `url(${bgr})` }}>
              <div className="cont-sp p-4">
                <h2 className="mobile-sp">
                  N'hésitez pas à nous envoyer votre CV.
                </h2>
                <button
                  type="submit"
                  className="btn submit-button-sp mb-5"
                  onClick={openModal}
                >
                  Postulez
                </button>
              </div>
            </div>
          </div>
        </div>
        <img src={gifs} className="gif-sp1 img-fluid" />
      </div>
    </>
  );
};

export default RejoinezPage;
