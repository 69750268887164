import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import bgr from "../../assets/bgo.png";
import bgs from "../../assets/bgsp.jpg";
import gifs from "../../assets/gits.gif";
import reg from "../../assets/rrr.svg";
import Modal from "../../components/modal";
import { showErrorToaster } from "../../components/toaster/error";
import MissionService from "../../services/mission/mission.service";
import "./index.scss";
const missionService = new MissionService();
const DetailsPage = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [offre, setOffre] = useState();

  const loadData = async () => {
    try {
      const res = await missionService?.getMissionById(id);
      setOffre(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }
  };
  useEffect(() => {
    loadData();
  }, [id]);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const openModal1 = () => setShowModal1(true);
  const closeModal1 = () => setShowModal1(false);
  return (
    <>
      <section className="realisation-section ">
        <div className="realisation-title text-center  mb-md-5 mb-3 ">
          {" "}
          <img
            src={reg}
            alt="Image à droite"
            className="realisation-img img-fluid mobile-width-rej"
          />
        </div>
        <div className="container">
          <h2 className="title-details">{offre?.title}</h2>
          <h3 className="description-title-details">Description :</h3>
          <h3 className="description-details">
            <div
              dangerouslySetInnerHTML={{
                __html: offre?.description,
              }}
            />
          </h3>
          {offre?.paye && <p className="details-p">Pays: {offre.paye}</p>}
          {offre?.duration == 0 ||
            (offre?.duration && (
              <p className="details-p">Duration: {offre.duration} ans</p>
            ))}
          {offre?.typeContrat && (
            <p className="details-p">Type Contrat: {offre.typeContrat}</p>
          )}
          {offre?.level && <p className="details-p">Niveau: {offre.level}</p>}
          {offre?.typeWork && (
            <p className="details-p">Type de travail: {offre.typeWork}</p>
          )}
          <div className="d-flex  justify-content-center align-items-center mb-5">
            <button
              type="submit"
              className="btn submit-button-apply"
              onClick={openModal}
            >
              Postulez
            </button>
          </div>
        </div>
        <Modal showModal={showModal} handleClose={closeModal} missionId={id} />
        <Modal
          showModal={showModal1}
          handleClose={closeModal1}
          missionId={null}
        />
        <div className="container-spontane mt-5 ">
          <h2 className="titre-spontane"> Candidature spontanée</h2>
          <div
            className="row   mb-4   card-sp-img cardr "
            style={{ backgroundImage: `url(${bgs})` }}
          >
            <div className=" col-7 ">
              <p className="p-spon">
                Si vous ne trouvez pas d'emploi qui vous corresponde, n'hésitez
                pas à nous envoyer votre CV. Nous sommes toujours à la recherche
                de personnes ambitieuses et talentueuses pour rejoindre notre
                équipe.
              </p>
              <button
                type="submit"
                className="btn submit-button-sp "
                onClick={openModal1}
              >
                Postulez
              </button>
            </div>
            <div className="col-3 "></div>
          </div>
          <img src={gifs} className="   gif-sp img-fluid" />
        </div>
        <div className="container justify-content-center position-relative mo-dis">
          <h2 className="titre-spontane"> Candidature spontanée</h2>
        </div>
        <div className="container d-flex justify-content-center heigth-sp position-relative mo-dis">
          <div className="row w-100 justify-content-center h-100 align-items-stretch h-100 mb-4 no-margin gx-0">
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <div className="cardr" style={{ backgroundImage: `url(${bgr})` }}>
                <div className="cont-sp">
                  <h2 className="mobile-sp">
                    N'hésitez pas à nous envoyer votre CV.
                  </h2>

                  <button
                    type="submit"
                    className="btn submit-button-sp mb-5"
                    onClick={openModal}
                  >
                    Postulez
                  </button>
                </div>
              </div>
            </div>
          </div>
          <img src={gifs} className="   gif-sp1 img-fluid" />
        </div>
      </section>{" "}
    </>
  );
};
export default DetailsPage;
