import React, { useEffect, useRef } from "react";
import "./index.scss";

import aboutH2 from "../../assets/aboutH2.svg";
import aboutH1 from "../../assets/abouth1.png";
import { useNavigate } from "react-router-dom";
import video from "../../assets/video1.mp4";
const AboutHome = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/about");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const videoRef = useRef(null);

  useEffect(() => {
    if (!videoRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoRef.current
            .play()
            .catch((err) => console.error("Erreur de lecture :", err)); // Gère les erreurs si la lecture échoue
        } else {
          videoRef.current.pause();
        }
      },
      {
        threshold: 0.5, // La vidéo doit être à moitié visible pour démarrer
      }
    );

    observer.observe(videoRef.current);

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);
  return (
    <div>
      <div
        className="row  w-100 justify-content-center align-items-center mt-md-5 mt-0  "
        style={{ marginLeft: 0 }}
      >
        <p className="aboutH-title mt-5">à propos de nous</p>
        <div className="col-10 mt-md-5 mt-0 d-flex align-items-center video-back">
          <video
            className="background-video w-100 mt-md-5 mt-0
             img-fluid"
            ref={videoRef}
            src={video} // URL de la vidéo
            muted // Nécessaire pour autoplay sans interaction utilisateur
            autoplay // Lecture automatique activée
            loop // La vidéo se répète une fois qu'elle est terminée
          />
        </div>
      </div>
      <div className="row  w-100 justify-content-center mt-3 align-items-center ">
        <div className="col-9 row justify-content-center  mt-md-3 mt-0">
          <div className="col-6">
            <p className="text-aboutH">MSIT CONSEIL</p>
          </div>
          <div className="col-6 d-flex  align-items-end justify-content-center justify-content-md-end p-0">
            <p className="text-aboutH1">
              AGENCE DE COMMUNICATION ET CONSULTING
            </p>
          </div>
        </div>
      </div>
      <div className="row w-100 justify-content-center align-items-center">
        {/* Colonne pour l'image */}
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <img src={aboutH1} alt="Image à droite" className="w-100 img-fluid" />
        </div>

        {/* Colonne pour le texte */}
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <p className="text-p-about">
            MSIT CONSEIL est une agence polyvalente à services complets basée en
            France et en Tunisie avec deux volets : le consulting et le digital.
            Nous offrons un accompagnement personnalisé à 360° aux entreprises
            grâce à une approche créative et stratégique qui génère des
            solutions de qualité.
          </p>
        </div>
      </div>
      <div className="row gx-0 w-100 justify-content-center align-items-center">
        {/* Première colonne vide pour la séparation */}
        <div className="col-12 col-md-6"></div>

        {/* Deuxième colonne contenant le bouton et l'image */}
        <div className="col-12 col-md-6">
          <div className="row gx-0 justify-content-center align-items-center">
            {/* Colonne pour le bouton */}
            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center mb-3 mb-md-0">
              <button className="button-about" onClick={handleNavigation}>
                En savoir plus sur nous
              </button>
            </div>

            {/* Colonne pour l'image */}
            <div className="col-12 d-flex align-items-end justify-content-end col-md-6">
              <img
                src={aboutH2}
                alt="Image à droite"
                className=" w-md-100 img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutHome;
