import { motion } from "framer-motion";
import React, { useState } from "react";
import b2 from "../../assets/br2.png";
import b3 from "../../assets/br3.png";
import b4 from "../../assets/br4.png";
import brandh from "../../assets/BRRR.svg";
import b1 from "../../assets/choi.png";
import phone2 from "../../assets/phones.svg";
import "./index.scss";

const BrandHomeMobile = () => {
  const [isZoomed, setIsZoomed] = useState(false);

  const [isThirdClick, setIsThirdClick] = useState(false); // Etat pour le 3ème clic
  const [opacity, setOpacity] = useState(false);
  const handleClick = () => {
    if (!isZoomed) {
      setIsZoomed(true); // Zoom in lors du premier clic
    } else {
      setIsThirdClick(true); // Effectuer le mouvement vers la gauche et disparition lors du 3ème clic
    }
  };
  const handleAnimationComplete = () => {
    if (isZoomed) {
      // Lorsque le zoom est terminé, mettre l'opacité à 0
      setOpacity(true);
    }
  };
  return (
    <motion.div
      style={{
        position: "relative",
        width: "100vw", // Utiliser toute la largeur de l'écran
        height: "100vh",
        overflow: "hidden",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#252424",
        cursor: `url(${require("../../assets/cursor.png")}), auto`,
      }}
      className="dispbrandMobile"
      animate={{
        backgroundColor: isThirdClick ? "#CCC" : "#252424",
        height: isThirdClick ? "250vh" : "100vh",
      }}
      transition={{
        duration: 1.5,
        ease: "easeInOut",
      }}
      onClick={handleClick}
    >
      {/* Image animée */}
      <motion.img
        src={brandh}
        alt="Grande Image"
        style={{
          width: "100vw",
          height: "100vh",
          objectFit: "cover",
          position: "absolute",
          zIndex: 100,
          borderRadius: isZoomed ? "30px" : "0px",
          opacity: opacity ? 0 : 1,
        }}
        animate={{
          position: "absolute",
          top: isZoomed ? "21.2vh" : "0", // Centrage vertical relatif à l'écran
          left: isZoomed ? "42vw" : "0", // Centrage horizontal relatif à l'écran
          width: isZoomed ? "45vw" : "100vw", // Taille adaptative
          height: isZoomed ? "55vh" : "100vh", // Taille adaptative
        }}
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
        onClick={handleClick}
        onAnimationComplete={handleAnimationComplete}
      />

      {/* Cadre central */}
      <motion.div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        animate={{
          x: isThirdClick ? "-100%" : "0",
        }}
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
      >
        <img
          src={phone2}
          alt="Image à droite"
          className="img-fluid"
          style={{
            width: "auto", // Largeur automatique pour respecter les proportions
            height: "auto", // Remplit la hauteur du conteneur
            objectFit: "contain", // Préserve les proportions de l'image
          }}
        />
      </motion.div>

      {/* Carte à droite (apparaît après le 2e clic) */}
      {isThirdClick && (
        <motion.div
          style={{
            position: "absolute",
            top: "0%",
            right: "-100%",
            backgroundColor: "transparent",
            borderRadius: "10px",
            width: "90%",
            opacity: 0,
          }}
          onClick={handleClick}
          animate={{
            right: isThirdClick ? "0%" : "-100%",
            opacity: isThirdClick ? 1 : 0,
          }}
          transition={{
            duration: 1.5,
            ease: "easeInOut",
          }}
        >
          <div className="row w-100 gx-0 position-relative">
            {/* Section 1 */}
            <motion.p
              className="title-overlay mt-3 mb-3"
              animate={{
                opacity: isThirdClick ? 1 : 0,
              }}
              transition={{
                duration: 1.5,
                ease: "easeInOut",
              }}
            >
              {" "}
              Pourquoi<span className="span-overlay">
                {" "}
                Nous Choisir ?{" "}
              </span>{" "}
            </motion.p>

            {/* Section avec les cartes */}
            <div className="row w-100 justify-content-center align-items-stretch h-100 mb-4">
              {/* Première section */}
              <div className="col-12 mb-3">
                <div
                  className="cardb"
                  style={{ backgroundImage: `url(${b1})` }}
                >
                  <div className="card-contentb">
                    <h2>Comprehensive Digital Expertise</h2>
                    <p>
                      Our team possesses deep expertise across all facets of the
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div
                  className="cardb"
                  style={{ backgroundImage: `url(${b2})` }}
                >
                  <div className="card-contentb">
                    <h2>Comprehensive Digital Expertise</h2>
                    <p>
                      Our team possesses deep expertise across all facets of the
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Section avec opacité */}
            <div className="row w-100 justify-content-center align-items-stretch h-100 mb-4">
              <div className="col-12 mb-3">
                <div
                  className="cardb"
                  style={{ backgroundImage: `url(${b3})` }}
                >
                  <div className="card-contentb">
                    <h2>Comprehensive Digital Expertise</h2>
                    <p>
                      Our team possesses deep expertise across all facets of the
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div
                  className="cardb"
                  style={{ backgroundImage: `url(${b4})` }}
                >
                  <div className="card-contentb">
                    <h2>Comprehensive Digital Expertise</h2>
                    <p>
                      Our team possesses deep expertise across all facets of the
                      digital landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default BrandHomeMobile;
