import React, { useEffect, useLayoutEffect, useState } from "react";

import { BsBan } from "react-icons/bs";
import { FiCheckSquare } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../components/appButton";
import DataTableDisplay from "../../../components/tableMission";
import { showErrorToaster } from "../../../components/toaster/error";
import RealisationService from "../../../services/realisation/realisation.service";
import RealisationResponsiveCards from "../RealisationCardResponsive";
const realisationService = new RealisationService();

function RealisationListePages() {
  const [realisation, setRealisation] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const loadData = async () => {
    setIsLoading(true);

    try {
      const res = await realisationService?.getAllRealisation();
      setRealisation(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleStatus = async (id, status) => {
    setIsLoading(true);
    const data = { status: !status };
    try {
      await realisationService?.toggleRStatus(id, data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }

    setIsLoading(false);
  };
  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  function getImageURL(candidatureId) {
    return `${apiUrl}/realisation/${candidatureId}/image`;
  }

  const columnsRealisation = [
    {
      name: "Titre",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.title != null && row?.title}
        </div>
      ),
      hide: "md",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="table-text capitalize">
          {row?.description?.length > 15
            ? `${row?.description?.substring(0, 15)}...`
            : row?.description}
        </div>
      ),
      sortable: true,
    },
    {
      name: "image",
      selector: (row) => (
        <div className="table-text capitalize">
          <img
            src={getImageURL(row?.id)}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
      ),
      sortable: true,
    },

    {
      name: "Date",
      selector: (row) => (
        <div className="table-text">{row?.date != null && row?.date}</div>
      ),
      hide: "md",
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => (
        <div
          style={{
            padding: "1rem",
          }}
        >
          <span
            style={{
              padding: "5px 10px",
              borderRadius: "12px",
              color: row?.status === true ? "#00AC0E" : "#EE0202",
              backgroundColor: row?.status === true ? "#D2FFD6" : "#FFD8D8",
            }}
          >
            {row?.status === true ? "Active" : "Inactive"}
          </span>
        </div>
      ),
      center: true,
      sortable: true,
    },
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <button
            data-bs-toggle="modal"
            data-bs-target="#createUserModal"
            type="button"
            className="btn btn-link"
            onClick={() => {
              navigate(`/edit-realisation/${row?.id}`);
              window.scrollTo(0, 0);
            }}
          >
            <label className="mx-1">
              <FiCheckSquare />
            </label>
          </button>
          <button
            className="btn btn-link"
            onClick={async () => {
              await handleStatus(row?.id, row?.status);
              await loadData();
            }}
            type="button"
          >
            {row?.status === true ? (
              <label className="mx-1">
                <BsBan style={{ color: "#EE0202" }} />
              </label>
            ) : (
              <label className="mx-1">
                <FiCheckSquare style={{ color: "#00AC0E" }} />
              </label>
            )}
          </button>
        </div>
      ),
    },
  ];
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3; // Adjust this number as needed

  // Calculate the offset
  const offset = currentPage * itemsPerPage;

  // Slice the missions list based on the current page and items per page
  const currentMissions = realisation.slice(offset, offset + itemsPerPage);

  // Handle page change
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <section className="usersPage">
      <div className="container-fluid">
        <div className="row my-0 my-md-5  align-items-center">
          <div className="col-auto">
            <h3 className="mb-0">Blogs </h3>
          </div>
          <div className="col-auto ms-auto">
            <AppButton
              text="Ajouter blog "
              color="bleu"
              size="medium"
              className="add-btn"
              onClick={() => {
                navigate("/add-realisation");
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <div className="d-flex my-5 justify-content-center align-items-center"></div>
        ) : (
          <div className=" my-0 my-md-5 py-2">
            {isMobile > 599 ? (
              <DataTableDisplay
                columns={columnsRealisation}
                data={realisation}
              />
            ) : (
              <>
                {realisation.map((item) => (
                  <RealisationResponsiveCards
                    key={item.id} // Ensure the key is unique for each item
                    handleStatus={handleStatus}
                    realisation={item}
                    loadData={loadData}
                  />
                ))}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(realisation.length / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

export default RealisationListePages;
