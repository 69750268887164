import React from "react";
import text from "../../assets/contactText.png";
import img from "../../assets/contactimg.svg";
import ContactSection from "../../components/contactForm";
import Contacts from "../../components/contacts";
import "./index.scss";
import { Helmet } from "react-helmet-async";
const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact - MSIT Conseil</title>
        <meta
          name="description"
          content="Bonjour ! MSIT Conseil est là pour
répondre à toutes vos questions.
Contactez-nous dès maintenant et
bénéficiez de notre expertise pour vos
projets !"
        />
      </Helmet>
      <section className="section-contact p-0   ">
        <div className="row row-contact p-0 justify-content-center  align-items-center ">
          <div className="col-12 col-md-6 mb-3 mb-md-0 mt-md-0 mt-4 ">
            <img
              src={text}
              alt="Image à droite"
              className="h-100 w-100 img-fluid"
            />
          </div>
          <div className="col-12 col-md-6  mb-md-0 mb-5">
            <img
              src={img}
              alt="Image à droite"
              className="h-100 w-100 img-fluid"
            />
          </div>
        </div>
      </section>
      <ContactSection />
      <Contacts />
    </>
  );
};

export default ContactPage;
