import React, { useEffect, useState } from "react";
import { FaClock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import blog1 from "../../assets/blog1.svg";
import { showErrorToaster } from "../../components/toaster/error";
import RealisationService from "../../services/realisation/realisation.service";
import "./index.scss";
import { Helmet } from "react-helmet-async";
const realisationService = new RealisationService();
const BlogPage = () => {
  const navigate = useNavigate();
  const [blogPosts, setRealisation] = useState([]);
  const handleNavigation = (id) => {
    navigate(`/blog/${id}`);
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const loadData = async () => {
    try {
      const res = await realisationService?.getAllActiveRealisation();
      setRealisation(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  function getImageURL(candidatureId) {
    return `${apiUrl}/realisation/${candidatureId}/image`;
  }
  return (
    <>
      <Helmet>
        <title>Blog - MSIT Conseil</title>
        <meta
          name="description"
          content="Bienvenue sur le blog de MSIT Conseil !
Découvrez nos articles, conseils et
actualités sur le consulting, et le
marketing digital pour booster votre
entreprise."
        />
      </Helmet>
      <div className="blog-section container-blog    mb-md-5 mb-0">
        <div className="blog-title text-center mb-md-5 mb-3">
          <img
            src={blog1}
            alt="Image à droite"
            className="blog-img img-fluid"
          />
        </div>
        {blogPosts.slice(0, 1).map((post) => (
          <div
            className="row mt-5  align-items-center blog-web"
            onClick={() => handleNavigation(post?.slug)}
          >
            <div className="col-6 mt-5">
              <img
                src={getImageURL(post?.id)} // Remplacez par votre image
                className=" img-fluid w-100 border-blog imghover"
              />
            </div>
            <div className="col-6 mt-5">
              <h2 className="blog-top-title mt5">{post?.title}</h2>
              <p className="blog-top-descrip mt-5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncateText(post.description, 300),
                  }}
                />
              </p>
              <div className="d-flex   ">
                <FaClock size={20} color="#002b58" />
                <p className="blog-top-time ">5 à 7 minutes de lecture</p>
              </div>
            </div>
          </div>
        ))}

        <div className="row g-0 mt-5 blog-mobile">
          {blogPosts.slice(0, 1).map((post) => (
            <div
              className="col-md-6"
              onClick={() => handleNavigation(post?.slug)}
            >
              <div className=" border-0 ">
                <img
                  src={getImageURL(post?.id)} // Remplacez par votre image
                  className="card-img-top border-blog imghover"
                  alt="Responsive Design"
                />
                <div className="card-body-blog">
                  <h5 className="card-title-blog mt-3 mb-3">{post?.title}</h5>
                  <p className="card-text-blog">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncateText(post.description, 300),
                      }}
                    />
                  </p>
                  <div className="d-flex justify-content-between text-muted">
                    <div className="d-flex  ">
                      <FaClock size={20} color="#002b58" />
                      <small className="card-time">
                        5-7 minutes de lecture
                      </small>
                    </div>

                    <small className="card-date">{post.date}</small>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row g-4 my-0 my-md-5 ">
          {blogPosts.slice(1).map((post) => (
            <div
              className="col-md-6"
              onClick={() => handleNavigation(post?.slug)}
            >
              <div className=" border-0 ">
                <img
                  src={getImageURL(post?.id)} // Remplacez par votre image
                  className="card-img-top border-blog imghover"
                  alt="Responsive Design"
                />
                <div className="card-body-blog">
                  <h5 className="card-title-blog  mt-3  mb-3 ">
                    {post?.title}
                  </h5>
                  <p className="card-text-blog">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: truncateText(post.description, 300),
                      }}
                    />
                  </p>
                  <div className="d-flex justify-content-between text-muted">
                    <div className="d-flex  ">
                      <FaClock size={20} color="#002b58" />
                      <small className="card-time">
                        5-7 minutes de lecture
                      </small>
                    </div>

                    <small className="card-date">{post.date}</small>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Deuxième carte */}
        </div>
      </div>
    </>
  );
};
export default BlogPage;
