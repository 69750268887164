import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import bal from "../../assets/bal1.svg";
import bgr from "../../assets/bgo.png";
import serf1 from "../../assets/devvv 1.png";
import serf2 from "../../assets/serf2.svg";
import servi1 from "../../assets/servi1.png";
import servi3 from "../../assets/servi3.png";
import service from "../../assets/service.png";
import servicem from "../../assets/servicem.svg";
import { showErrorToaster } from "../../components/toaster/error";
import MissionService from "../../services/mission/mission.service";
import "./index.scss";
const missionService = new MissionService();

const ServicePage = () => {
  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isIpad, setIsIpad] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768); // Mobile : ≤ 768px
      setIsIpad(width > 768 && width <= 1024); // Écran mobile si ≤ 768px
    };
    handleResize(); // Vérifie la taille au chargement
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const navigate = useNavigate();
  const handleNavigation1 = () => {
    navigate("/consulting");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const handleNavigation2 = () => {
    navigate("/developpemnt");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const handleNavigation3 = () => {
    navigate("/design");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const handleNavigation4 = () => {
    navigate("/marketing");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const handleNavigationd = () => {
    navigate("/rejoignez-nous");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const [items, setMission] = useState([]);

  const handleNavigation = (id) => {
    navigate(`/offerDetails/${id}`);
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const loadData = async () => {
    try {
      const res = await missionService?.getAllActiveMission();
      setMission(res?.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Une erreur est survenue.";
      showErrorToaster(errorMessage);
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  const limitedOffres = items.slice(0, 3);
  return (
    <>
      <section className="service-section container1">
        <div className="service-title text-center w-100 mb-5">
          {" "}
          <img
            src={service}
            alt="Image à droite"
            className="service-img img-fluid w-100 serviceWeb"
          />
          <img
            src={servicem}
            alt="Image à droite"
            className="service-img img-fluid w-100 serviceMobile"
          />
        </div>
        <div className="row">
          <p className="service-description  ">
            Notre équipe de consultants vous assistent tout au long de votre
            démarche allant de la conception à la mise en œuvre de solutions
            informatiques décisionnelles adaptées au contexte et au projet de
            votre entreprise.
            <br /> En parallèle grâce à notre équipe d'experts web et digitale
            réactifs et polyvalents, nous assurerons un suivi permanent durant
            toutes les phases de digitalisation.
          </p>
        </div>
        <div className="table-service">
          <div
            className="row  justify-content-center "
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div className="col-12 col-md-5">
              {" "}
              <motion.p
                className="title-ser  "
                initial={{ scale: 1, x: 0, color: "#312f2f" }}
                animate={
                  hover
                    ? isMobile
                      ? { scale: 1.2, x: 30, color: "#002B58" } // Version mobile
                      : isIpad
                      ? { scale: 1.3, x: 50, color: "#002B58" } // Version iPad/Tablette
                      : { scale: 1.5, x: 155, color: "#002B58" } // Version desktop
                    : { scale: 1, x: 0, color: "#312f2f" }
                }
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                onClick={handleNavigation1}
              >
                CONSULTING
              </motion.p>
            </div>
            <div className="col-12 col-md-7 des-ser-container">
              <motion.p
                className="des-ser"
                initial={{ opacity: 1 }}
                animate={{ opacity: hover ? 0 : 1 }}
                transition={{ duration: 0.5 }}
              ></motion.p>

              {/* Image animée */}
              {hover && (
                <motion.div
                  className="image-overlay"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src={serf2} // Remplacez par votre URL d'image
                    alt="Consulting"
                  />
                </motion.div>
              )}
            </div>
          </div>
          <motion.hr
            className="hr-ser"
            initial={{ backgroundColor: "black" }}
            animate={
              hover
                ? { backgroundColor: "#002B58" }
                : { backgroundColor: "black" }
            }
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          />
          <div
            className="row   justify-content-center mt-5 mb-4 "
            onMouseEnter={() => setHover1(true)}
            onMouseLeave={() => setHover1(false)}
          >
            <div className="col-12 col-md-5">
              {" "}
              <motion.p
                className="title-ser  "
                initial={{ scale: 1, x: 0, color: "#312f2f" }}
                animate={
                  hover1
                    ? isMobile
                      ? { scale: 1, x: 0, color: "#002B58" } // Version mobile
                      : isIpad
                      ? { scale: 1.3, x: 50, color: "#002B58" } // Version iPad/Tablette
                      : { scale: 1.5, x: 155, color: "#002B58" } // Version desktop
                    : { scale: 1, x: 0, color: "#312f2f" }
                }
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                onClick={handleNavigation2}
              >
                DÉVELOPPEMENT
              </motion.p>
            </div>
            <div className="col-12 col-md-7 des-ser-container">
              {" "}
              <motion.p
                className="des-ser"
                initial={{ opacity: 1 }}
                animate={{ opacity: hover1 ? 0 : 1 }}
                transition={{ duration: 0.5 }}
              ></motion.p>
              {/* Image animée */}
              {hover1 && (
                <motion.div
                  className="image-overlay"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src={serf1} // Remplacez par votre URL d'image
                    alt="Consulting"
                  />
                </motion.div>
              )}
            </div>
          </div>
          <motion.hr
            className="hr-ser"
            initial={{ backgroundColor: "black" }}
            animate={
              hover1
                ? { backgroundColor: "#002B58" }
                : { backgroundColor: "black" }
            }
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          />
          <div
            className="row  justify-content-center   mt-5 mb-4  "
            onMouseEnter={() => setHover2(true)}
            onMouseLeave={() => setHover2(false)}
          >
            <div className="col-12 col-md-5">
              {" "}
              <motion.p
                className="title-ser  "
                initial={{ scale: 1, x: 0, color: "#312f2f" }}
                animate={
                  hover2
                    ? isMobile
                      ? { scale: 1.2, x: 30, color: "#002B58" } // Version mobile
                      : isIpad
                      ? { scale: 1.3, x: 50, color: "#002B58" } // Version iPad/Tablette
                      : { scale: 1.5, x: 155, color: "#002B58" } // Version desktop
                    : { scale: 1, x: 0, color: "#312f2f" }
                }
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                onClick={handleNavigation3}
              >
                DESIGN
              </motion.p>
            </div>
            <div className="col-12 col-md-7 des-ser-container">
              <motion.p
                className="des-ser"
                initial={{ opacity: 1 }}
                animate={{ opacity: hover2 ? 0 : 1 }}
                transition={{ duration: 0.5 }}
              ></motion.p>
              {/* Image animée */}
              {hover2 && (
                <motion.div
                  className="image-overlay"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src={servi3} // Remplacez par votre URL d'image
                    alt="Consulting"
                  />
                </motion.div>
              )}
            </div>
          </div>
          <motion.hr
            className="hr-ser"
            initial={{ backgroundColor: "black" }}
            animate={
              hover2
                ? { backgroundColor: "#002B58" }
                : { backgroundColor: "black" }
            }
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          />
          <div
            className="row  justify-content-center   mt-5 mb-4 "
            onMouseEnter={() => setHover3(true)}
            onMouseLeave={() => setHover3(false)}
          >
            <div className="col-12 col-md-5">
              <motion.p
                className="title-ser  "
                initial={{ scale: 1, x: 0, color: "#312f2f" }}
                animate={
                  hover3
                    ? isMobile
                      ? { scale: 1.2, x: 30, color: "#002B58" } // Version mobile
                      : isIpad
                      ? { scale: 1.3, x: 50, color: "#002B58" } // Version iPad/Tablette
                      : { scale: 1.5, x: 155, color: "#002B58" } // Version desktop
                    : { scale: 1, x: 0, color: "#312f2f" }
                }
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                onClick={handleNavigation4}
              >
                MARKETING
              </motion.p>
            </div>
            <div className="col-12 col-md-7 des-ser-container">
              <motion.p
                className="des-ser"
                initial={{ opacity: 1 }}
                animate={{ opacity: hover3 ? 0 : 1 }}
                transition={{ duration: 0.5 }}
              ></motion.p>
              {hover3 && (
                <motion.div
                  className="image-overlay"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src={servi1} // Remplacez par votre URL d'image
                    alt="Consulting"
                  />
                </motion.div>
              )}
            </div>
          </div>
          <motion.hr
            className="hr-ser"
            initial={{ backgroundColor: "black" }}
            animate={
              hover3
                ? { backgroundColor: "#002B58" }
                : { backgroundColor: "black" }
            }
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          />
        </div>
      </section>
      <div className="position-relative">
        <motion.img
          src={bal} // Remplacez par l'URL ou le chemin de votre image
          alt="Image flottante"
          animate={{
            y: [0, -20, 0], // Déplacement vertical (flottement)
          }}
          transition={{
            duration: 2, // Durée d'un cycle de flottement
            repeat: Infinity, // Répéter l'animation indéfiniment
            repeatType: "loop", // Répéter en boucle
            ease: "easeInOut", // Type d'easing
          }}
          className="balImage"
        />
      </div>
      <div className="serv-container">
        <div className="row  justify-content-center  align-items-center mb-md-5 mb-0 ">
          <p className="title-serv">Découvre nos offres</p>{" "}
 
        </div>
        <div className="row w-100 justify-content-center align-items-stretch h-100 mb-4 gx-0 ">
          {limitedOffres.map((item, index) => (
            <div className="col-12 col-sm-6 col-md-4 mb-3 g-3" key={index}>
              <div
                className="cardr cardhover"
                style={{ backgroundImage: `url(${bgr})` }}
              >
                <div className="card-contentr">
                  <h2>{item.title}</h2>
                  {/*    <p>{item?.createdAt?.split("T")[0]}</p> */}
                  <button
                    type="submit"
                    className="btn submit-button-details"
                    onClick={() => {
                      handleNavigation(item.id);
                    }}
                  >
                    Voir l'offre
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex  justify-content-center align-items-center mt-5 mb-5">
            <button
              type="submit"
              className="btn submit-button-apply-ser1"
              onClick={handleNavigationd}
            >
              Voir plus
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePage;
