import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import reali from "../../assets/realiH.svg";
import sm1 from "../../assets/sm1.png";
import sm10 from "../../assets/sm10.png";
import sm11 from "../../assets/sm11.png";
import sm12 from "../../assets/sm12.png";
import sm13 from "../../assets/sm13.png";
import sm2 from "../../assets/sm2.png";
import sm3 from "../../assets/sm3.png";
import sm4 from "../../assets/sm4.png";
import sm5 from "../../assets/sm5.png";
import sm6 from "../../assets/sm6.png";
import sm7 from "../../assets/sm7.png";
import sm8 from "../../assets/sm8.png";
import sm9 from "../../assets/sm9.png";
import smr1 from "../../assets/smr1.png";
import smr10 from "../../assets/smr10.png";
import smr11 from "../../assets/smr11.png";
import smr2 from "../../assets/smr2.png";
import smr3 from "../../assets/smr3.png";
import smr4 from "../../assets/smr4.png";
import smr5 from "../../assets/smr5.png";
import smr6 from "../../assets/smr6.png";
import smr7 from "../../assets/smr7.png";
import smr8 from "../../assets/smr8.png";
import smr9 from "../../assets/smr9.png";
import smrr1 from "../../assets/smrr1.png";
import smrr10 from "../../assets/smrr10.png";
import smrr11 from "../../assets/smrr11.png";
import smrr12 from "../../assets/smrr12.png";
import smrr2 from "../../assets/smrr2.png";
import smrr3 from "../../assets/smrr3.png";
import smrr4 from "../../assets/smrr4.png";
import smrr5 from "../../assets/smrr5.png";
import smrr6 from "../../assets/smrr6.png";
import smrr7 from "../../assets/smrr7.png";
import smrr8 from "../../assets/smrr8.png";
import smrr13 from "../../assets/smrr13.png";

import "./index.scss";

const RealisationHome = () => {
  // Animation controls for the image
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/realisation");
    window.scrollTo(0, 0); // Navigue vers la page "About"
  };
  const settingsRow1 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    arrows: false, // Direction de droite à gauche
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsRow2 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: -1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: -1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: -1,
        },
      },
    ],
  };

  const settingsRow3 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 4, // Nombre d'images visibles en même temps
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // En dessous de 768px (mobile)
        settings: {
          slidesToShow: 3, // Affiche 3 drapeaux
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // En dessous de 480px (petit mobile)
        settings: {
          slidesToShow: 2, // Affiche 2 drapeaux
          slidesToScroll: 1,
        },
      },
    ],
    // Direction de droite à gauche
  };

  const rows1 = [
    { id: 1, image: sm1, alt: " social media 1" },
    { id: 2, image: sm2, alt: " social media 2" },
    { id: 3, image: sm3, alt: "social media 3" },
    { id: 4, image: sm4, alt: "social media 4" },
    { id: 5, image: sm5, alt: " social media 5" },
    { id: 6, image: sm6, alt: " social media 6" },
    { id: 7, image: sm7, alt: "social media 7" },
    { id: 8, image: sm8, alt: "social media 8" },
    { id: 9, image: sm9, alt: " social media 9" },
    { id: 10, image: sm10, alt: " social media 10" },
    { id: 11, image: sm11, alt: "social media 11" },
    { id: 12, image: sm12, alt: "social media 12" },
    { id: 13, image: sm13, alt: "social media 13" },
  ];
  const rows2 = [
    { id: 1, image: smr1, alt: " social media 1" },
    { id: 2, image: smr2, alt: " social media 2" },
    { id: 3, image: smr3, alt: "social media 3" },
    { id: 4, image: smr4, alt: "social media 4" },
    { id: 5, image: smr5, alt: " social media 5" },
    { id: 6, image: smr6, alt: " social media 6" },
    { id: 7, image: smr7, alt: "social media 7" },
    { id: 8, image: smr8, alt: "social media 8" },
    { id: 9, image: smr9, alt: " social media 9" },
    { id: 10, image: smr10, alt: " social media 10" },
    { id: 11, image: smr11, alt: "social media 11" },
  ];
  const rows3 = [
    { id: 1, image: smrr1, alt: " social media 1" },
    { id: 2, image: smrr2, alt: " social media 2" },
    { id: 3, image: smrr3, alt: "social media 3" },
    { id: 4, image: smrr4, alt: "social media 4" },
    { id: 5, image: smrr5, alt: " social media 5" },
    { id: 6, image: smrr6, alt: " social media 6" },
    { id: 7, image: smrr7, alt: "social media 7" },

    { id: 8, image: smrr10, alt: " social media 10" },
    { id: 9, image: smrr11, alt: "social media 11" },
    { id: 10, image: smrr12, alt: "social media 12" },
    { id: 11, image: smrr1, alt: "social media 13" },
  ];
  const controlsImage = useAnimation();
  const [refContainer, inViewContainer] = useInView({ threshold: 0.2 });

  // Animation controls for rows
  const controlsRow1 = useAnimation();
  const controlsRow2 = useAnimation();
  const controlsRow3 = useAnimation();

  const [refRow1, inViewRow1] = useInView({ threshold: 0.1 });
  const [refRow2, inViewRow2] = useInView({ threshold: 0.1 });
  const [refRow3, inViewRow3] = useInView({ threshold: 0.1 });

  // Image animation effect
  useEffect(() => {
    if (inViewContainer) {
      controlsImage.start("visible");
    } else {
      controlsImage.start("hidden");
    }
  }, [inViewContainer, controlsImage]);

  useEffect(() => {
    if (inViewRow1) controlsRow1.start("visible");
    else controlsRow1.start("hidden");
  }, [inViewRow1, controlsRow1]);

  useEffect(() => {
    if (inViewRow2) controlsRow2.start("visible");
    else controlsRow2.start("hidden");
  }, [inViewRow2, controlsRow2]);

  useEffect(() => {
    if (inViewRow3) controlsRow3.start("visible");
    else controlsRow3.start("hidden");
  }, [inViewRow3, controlsRow3]);

  const backgroundVariants = {
    hidden: {
      opacity: 0, // Fond invisible au départ
    },
    visible: {
      opacity: 0.5, // Fond semi-transparent
      transition: {
        duration: 2, // Durée de la transition
        ease: "easeInOut", // Effet fluide
      },
    },
  };

  const imageVariants = {
    hidden: {
      scale: 1, // Taille initiale
      top: "0%",
      left: "50%",
      x: "-50%",
      opacity: 0, // Image invisible au départ
    },
    visible: {
      scale: 5, // Zoom augmenté
      top: "50%", // Se déplace vers le centre verticalement
      left: "50%", // Centre horizontalement
      x: "-50%",
      opacity: 1, // Image devient visible
      transition: {
        duration: 2, // Durée de la transition
        ease: "easeInOut", // Effet fluide
      },
    },
  };
  const sliderVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.5 } },
  };
  return (
    <div className="realisation-container1 " ref={refContainer}>
      <p className="serviceH-title mb-md-5 mb-0 mt-md-5 mt-0">
        nos réalisations{" "}
      </p>
      {/* Conteneur position relative */}
      <div className="image-slider-container p-0">
        <motion.div
          className="background-overlay p-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.4 }}
          transition={{ duration: 2, ease: "easeInOut" }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black", // Couleur de fond
            zIndex: 1,
          }}
        ></motion.div>
        <motion.img
          onClick={handleNavigation}
          src={reali}
          alt="Image animée"
          className="image-reali"
          initial="hidden"
          animate={controlsImage}
          variants={imageVariants}
        />
        <motion.div
          ref={refRow1}
          className="slider-row "
          initial="hidden"
          animate={controlsRow1}
          variants={sliderVariants}
        >
          <Slider {...settingsRow1}>
            {rows1.map((row) => (
              <div key={row.id} className="row-slider">
                <img src={row.image} alt={row.alt} className="image-reali1" />
              </div>
            ))}
          </Slider>
        </motion.div>
        <motion.div
          ref={refRow2}
          className="slider-row "
          initial="hidden"
          animate={controlsRow2}
          variants={sliderVariants}
        >
          <Slider {...settingsRow2}>
            {rows2.map((row) => (
              <div key={row.id} className="row-slider">
                <img src={row.image} alt={row.alt} />
              </div>
            ))}
          </Slider>
        </motion.div>
        <motion.div
          ref={refRow3}
          className="slider-row"
          initial="hidden"
          animate={controlsRow3}
          variants={sliderVariants}
        >
          <Slider {...settingsRow3}>
            {rows3.map((row) => (
              <div key={row.id} className="row-slider">
                <img src={row.image} alt={row.alt} />
              </div>
            ))}
          </Slider>
        </motion.div>
      </div>
    </div>
  );
};

export default RealisationHome;
